export const data = [
  {
    title: "The Daily WTF",
    description: "A comedy podcast about software development gone wrong.",
    imageUrl: "https://picsum.photos/id/100/200/300",
    duration: 326,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["comedy", "technology", "software development"],
    category: "Comedy",
    id: "7f5b89f3-a080-4c01-95f3-14d9a182799b",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/Printing+Engineering++Standup+Comedy+by+Samay+Raina.mp3",
  },
  {
    title: "Tech Trends Weekly",
    description: "Latest trends and news in the tech world.",
    imageUrl: "https://picsum.photos/id/101/200/300",
    duration: 1001,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["technology", "news", "innovation"],
    category: "Technology",
    id: "16b12bc4-ab43-471f-b50a-db61b791dd26",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/God-Tier+Developer+Roadmap.mp3",
  },
  {
    title: "Code Coffee",
    description: "Casual conversations about programming over coffee.",
    imageUrl: "https://picsum.photos/id/102/200/300",
    duration: 311,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["programming", "lifestyle", "career"],
    category: "Lifestyle",
    id: "8344159c-0bd2-4a7b-bc2c-389f9b48125b",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/Coffee+and+Code_+Episode+1+-+Java+Environment+Setup.mp3",
  },
  {
    title: "Design Matters",
    description: "Exploring design principles and creativity.",
    imageUrl: "https://picsum.photos/id/103/200/300",
    duration: 713,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["design", "creativity", "art"],
    category: "Design",
    id: "effd9148-3a49-4b20-9d7d-8be8df5533a9",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/The+Easy+Way+to+Design+Top+Tier+Websites.mp3",
  },
  {
    title: "Startup Stories",
    description: "Interviews with startup founders about their journey.",
    imageUrl: "https://picsum.photos/id/104/200/300",
    duration: 1314,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["startups", "entrepreneurship", "business"],
    category: "Business",
    id: "4d33cd71-3b66-4220-b7c2-f3a94e33e334",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/Small+Business+Idea+-+Part+1.mp3",
  },
  {
    title: "The Coding Sandbox",
    description: "Deep dives into coding projects and tutorials.",
    imageUrl:
      "https://imgs.search.brave.com/s4I9mSMYxbiZ66R0IwhHYAEogHvTU2JbcAegxDrNhac/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly93d3cu/aHVic3BvdC5jb20v/aHViZnMvaG93LXRv/LXN0YXJ0LWNvZGlu/Zy0xLmpwZw",
    duration: 712,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["coding", "software development", "tutorials"],
    category: "Education",
    id: "53101de6-cd59-4068-ae48-6682e52b9d76",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/Every+React+Concept+Explained+in+12+Minutes.mp3",
  },
  {
    title: "Podcast.ai",
    description: "Joe Rogan interviews Steve Jobs",
    imageUrl:
      "https://static-content-for-text.s3.amazonaws.com/podcasts/Screenshot+2024-10-04+210623.jpg",
    duration: 244,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["fitness", "health", "wellness"],
    category: "shitTalk",
    id: "9d2a8922-ef80-43a7-b890-0731607b9ec3",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/d7fbe0d8b4fc0a927e0e5a81a7d55181.mp3",
  },
  {
    title: "fun Podcast.ai",
    description: "Lex Fridman interviews Richard Feynman",
    imageUrl:
      "https://static-content-for-text.s3.amazonaws.com/podcasts/Screenshot+2024-10-04+210646.jpg",
    duration: 1266,
    host: "Himank Singh",
    hostImageProfile:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/car.avif",
    hostId: "25ce565b-6171-47f7-88cb-1bf927901e28",
    tags: ["fitness", "health", "wellness"],
    category: "shitTalk",
    id: "4896907f-7e75-4168-8812-7b3ffa8ea6fb",
    url: "https://static-content-for-text.s3.amazonaws.com/podcasts/d7fbe0d8b4fc0a927e0e5a81a7d55181.mp3",
  },
];

// https://static-content-for-text.s3.amazonaws.com/podcasts/32e2bed1df801d2a77be80eaaf0a7924+(1).mp3
// https://static-content-for-text.s3.amazonaws.com/podcasts/d7fbe0d8b4fc0a927e0e5a81a7d55181.mp3
/*
  ,
    {
    title: "Science for Everyone",
    description: "Explaining complex scientific concepts in simple terms.",
    imageUrl: "https://picsum.photos/id/106/200/300",
    duration: { hours: 1, minutes: 30, seconds: 0 },
    host: "Laura Lee",
    tags: ["science", "education", "technology"],
    category: "Science",
    url : ""
  },
  {
    title: "Gaming World",
    description: "Discussions and news about the latest in gaming.",
    imageUrl: "https://picsum.photos/id/107/200/300",
    duration: { hours: 1, minutes: 10, seconds: 0 },
    host: "Mike Rogers",
    tags: ["gaming", "news", "reviews"],
    category: "Gaming",
    url : ""
  },
  {
    title: "Mindfulness Matters",
    description: "Exploring mindfulness techniques for a peaceful life.",
    imageUrl: "https://picsum.photos/id/109/200/300",
    duration: { hours: 0, minutes: 55, seconds: 0 },
    host: "Mia Wong",
    tags: ["mindfulness", "wellness", "meditation"],
    category: "Self-Improvement",
  },
  {
    title: "Tech Disruptors",
    description: "Interviews with tech leaders changing the industry.",
    imageUrl: "https://picsum.photos/id/110/200/300",
    duration: { hours: 0, minutes: 50, seconds: 0 },
    host: "James Parker",
    tags: ["technology", "innovation", "business"],
    category: "Technology",
  },
  {
    title: "History Bites",
    description: "Short stories and fun facts about world history.",
    imageUrl: "https://picsum.photos/id/111/200/300",
    duration: { hours: 0, minutes: 25, seconds: 0 },
    host: "Grace Hernandez",
    tags: ["history", "education", "storytelling"],
    category: "History",
  },
  {
    title: "Movie Magic",
    description: "Behind the scenes of filmmaking and reviews.",
    imageUrl: "https://picsum.photos/id/112/200/300",
    duration: { hours: 1, minutes: 20, seconds: 0 },
    host: "Oscar Patel",
    tags: ["movies", "reviews", "filmmaking"],
    category: "Entertainment",
  },
  {
    title: "The Art of Speaking",
    description: "Tips and techniques for public speaking and communication.",
    imageUrl: "https://picsum.photos/id/113/200/300",
    duration: { hours: 0, minutes: 60, seconds: 0 },
    host: "Eleanor Williams",
    tags: ["communication", "public speaking", "self-improvement"],
    category: "Self-Improvement",
  },
  {
    title: "World of Finance",
    description: "Insights into personal finance, investing, and markets.",
    imageUrl: "https://picsum.photos/id/114/200/300",
    duration: { hours: 1, minutes: 0, seconds: 0 },
    host: "Daniel Kim",
    tags: ["finance", "investing", "markets"],
    category: "Business",
  },
  {
    title: "Travel Tails",
    description: "Adventures and experiences from traveling the globe.",
    imageUrl: "https://picsum.photos/id/115/200/300",
    duration: { hours: 0, minutes: 45, seconds: 0 },
    host: "Sophie Davis",
    tags: ["travel", "adventure", "lifestyle"],
    category: "Travel",
  },
  {
    title: "Music Mavericks",
    description: "Exploring the latest trends and artists in the music world.",
    imageUrl: "https://picsum.photos/id/116/200/300",
    duration: { hours: 1, minutes: 10, seconds: 0 },
    host: "Oliver Bennett",
    tags: ["music", "artists", "trends"],
    category: "Music",
  },
  {
    title: "The Sports Arena",
    description: "In-depth analysis and news about various sports.",
    imageUrl: "https://picsum.photos/id/117/200/300",
    duration: { hours: 1, minutes: 30, seconds: 0 },
    host: "Liam Thompson",
    tags: ["sports", "news", "analysis"],
    category: "Sports",
  },
  {
    title: "Parenting Tips 101",
    description: "Advice and tips for modern-day parenting.",
    imageUrl: "https://picsum.photos/id/118/200/300",
    duration: { hours: 0, minutes: 40, seconds: 0 },
    host: "Natalie Green",
    tags: ["parenting", "family", "lifestyle"],
    category: "Family",
  },
  {
    title: "The Mental Health Hour",
    description: "Discussions and resources for mental well-being.",
    imageUrl: "https://picsum.photos/id/119/200/300",
    duration: { hours: 1, minutes: 0, seconds: 0 },
    host: "Rebecca Ford",
    tags: ["mental health", "well-being", "psychology"],
    category: "Health",
  },
  {
    title: "Entrepreneur Essentials",
    description: "Key strategies and tips for entrepreneurs and startups.",
    imageUrl: "https://picsum.photos/id/120/200/300",
    duration: { hours: 0, minutes: 50, seconds: 0 },
    host: "Henry Nelson",
    tags: ["entrepreneurship", "startups", "business"],
    category: "Business",
  },
  {
    title: "The Book Club",
    description: "Weekly discussions on books and literature.",
    imageUrl: "https://picsum.photos/id/121/200/300",
    duration: { hours: 1, minutes: 15, seconds: 0 },
    host: "Ella Robinson",
    tags: ["books", "literature", "reviews"],
    category: "Literature",
  },
  {
    title: "Tech for Good",
    description: "How technology is being used to solve global challenges.",
    imageUrl: "https://picsum.photos/id/122/200/300",
    duration: { hours: 1, minutes: 5, seconds: 0 },
    host: "Tom Wright",
    tags: ["technology", "social good", "innovation"],
    category: "Technology",
  },
  {
    title: "True Crime Tales",
    description: "Exploring famous true crime stories from history.",
    imageUrl: "https://picsum.photos/id/123/200/300",
    duration: { hours: 1, minutes: 20, seconds: 0 },
    host: "Emma Kelly",
    tags: ["true crime", "history", "storytelling"],
    category: "Crime",
  },
  {
    title: "Cooking with Flavor",
    description: "Delicious recipes and cooking tips for food lovers.",
    imageUrl: "https://picsum.photos/id/124/200/300",
    duration: { hours: 0, minutes: 35, seconds: 0 },
    host: "George Simmons",
    tags: ["cooking", "food", "recipes"],
    category: "Food",
  },
  {
    title: "Sustainability Now",
    description: "Conversations on sustainability and environmental impact.",
    imageUrl: "https://picsum.photos/id/125/200/300",
    duration: { hours: 0, minutes: 55, seconds: 0 },
    host: "Clara Morgan",
    tags: ["sustainability", "environment", "green living"],
    category: "Environment",
  },
  {
    title: "The Language Lab",
    description: "Exploring languages, linguistics, and communication.",
    imageUrl: "https://picsum.photos/id/126/200/300",
    duration: { hours: 1, minutes: 10, seconds: 0 },
    host: "Victor Adams",
    tags: ["languages", "linguistics", "communication"],
    category: "Education",
  },
  {
    title: "Philosophy for Life",
    description: "Philosophical discussions for everyday thinking.",
    imageUrl: "https://picsum.photos/id/127/200/300",
    duration: { hours: 1, minutes: 5, seconds: 0 },
    host: "Ethan Cooper",
    tags: ["philosophy", "life", "thinking"],
    category: "Education",
  },
*/
