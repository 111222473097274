import { createSlice } from "@reduxjs/toolkit";

const currentFollowPreview = createSlice({
    name : "currentFollowPreview",
    initialState : {
        data : [],
        type: "",
        userName : ""
    },
    reducers : {
        setData : function(state, action){
            state.data = action.payload.data;
            state.type = action.payload.type
            state.userName = action.payload.userName;
        }
    }
})

export const {setData} = currentFollowPreview.actions;
export default currentFollowPreview.reducer;