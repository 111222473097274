import api from "./api";
import { data } from "../assets/Data";
import { setCookie } from "./cookie";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/init";
import { notify } from "./notifyHarshit";

export async function discoverPodcast(category) {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return data;
}

export async function upsertUser({
  uid,
  email,
  displayName,
  photoURL,
  provider,
}) {
  // get user id from db
  let user;
  try {
    user = (await api.post("/auth/check-or-insert", { emailID: email })).data;
  } catch (err) {
    console.log(err);
    throw err;
  }

  // setCookie("access-token", result1.userToken);
  setCookie("userID", user.id);


  // sync user info if missing
  let missingInfo = null;

  if (!user?.emailID) {
    if (!missingInfo) missingInfo = {};
    missingInfo.emailId = email;
  }
  if (!user?.userName) {
    if (!missingInfo) missingInfo = {};
    missingInfo.userName = displayName;
  }
  if (!user?.imageUrl) {
    if (!missingInfo) missingInfo = {};
    missingInfo.imageUrl = photoURL || "/avatar.png";
  }

  user.imageUrl = user.imageUrl || "/avatar.png"

  if (!missingInfo) {
    return { id: user.id, ...user, email };
  }

  try {
    const updatedUSer = (
      await api.post("/user/update-profile", {
        id: user.id,
        userData: {
          emailID: email,
          userName: displayName,
          imageUrl: photoURL || "/avatar.png",
        },
      })
    ).data;

    return { id: user.id, ...updatedUSer.result.Attributes, email };
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function onAuthStateChangePromise() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        resolve({ user, unsubscribe });
      },
      (err) => {
        reject({ err, unsubscribe });
      }
    );
  });
}

export async function fetchPodcastFromId(id) {
  try {
    const podcast = (await api.post("/userpodcast/podcast-details-by-id", {
      podcastID: id,
    })).data.user[0];

    return podcast;
  } catch (err) {
    throw err;
  }
}

export async function followUser(payload) {
  try{
    const followData = (await api.post("/userfollower/store-follower", payload)).data.userFollowerData;
    return followData;
  }
  catch(err){
    throw err;
  }
}
