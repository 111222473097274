import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import SideBar from "../../components/SideBar";
import AudioPlayer from "../../components/AudioPlayer";

import { Share2, LoaderCircle } from "lucide-react";

import { setAudioPlayerStatus } from "../../redux/slice/Appconfig";
import { setCurrentPodCast } from "../../redux/slice/podcast/currentPlaingPodcast";
import {
  addFollower,
  fetchFollowerFollowing,
  fetchUserPodCast,
  fetchUserProfileData,
} from "../../redux/slice/userProfile";

import { followUser } from "../../utils/api-helper";

import FollowModal from "../../components/modal/followModal";

import { setData } from "../../redux/slice/follow/userFollowPreview";

function UserProfile() {
  const { follow, user, podcast } = useSelector(
    (state) => state.userCustomProfile
  );
  const { id: userID } = useParams();
  const dispatch = useDispatch();
  const { userData, error: userError, loading: loadingUser } = user;
  const { following, followers, loading: followloading, error } = follow;
  const { data, error: errorPodcast, loading: loadingPodcast } = podcast;
  const { id: myUserID } = useSelector((state) => state.profile);
  const { user: AuthUser, loading } = useSelector((state) => state.auth);
  const { imageUrl, email, userName } = userData;
  const [loadingFollow, setLoading] = useState(false);
  useEffect(() => {
    dispatch(fetchUserProfileData({ userID }));
    dispatch(fetchFollowerFollowing({ userID }));
    dispatch(fetchUserPodCast({ userID }));
  }, []);

  const followModalRef = useRef();

  async function Follow() {
    const payload = {
      followerID: myUserID,
      followerUserName: AuthUser.userName,
      followerUserProfile: AuthUser.imageUrl,
      followingID: userID,
      followingUserName: userName,
      followingUserProfile: imageUrl,
      followType: "follow",
      status: 1,
    };
    console.log(payload);
    setLoading(true);
    try {
      const data = await followUser(payload);
      dispatch(addFollower(data));
    } catch (error) {
      alert(error?.message);
      console.log(error);
    }
    setLoading(false);
  }
  const followed = AuthUser
    ? followers.some((item) => item.followerID === myUserID)
    : false;

  return (
    <Fragment>
      <AudioPlayer />
      <SideBar />
      <Navbar />
      {loadingUser ? (
        <Fragment>
          <div
            htmlFor="image"
            className="size-[200px] animate-pulse rounded-full mb-6 bg-gray  self-center"
          />
          <div
            htmlFor="image"
            className="h-8 w-2/3 animate-pulse rounded-full mb-6 bg-gray  self-center"
          />
          <div
            htmlFor="image"
            className="h-8 w-1/3 animate-pulse rounded-full mb-6 bg-gray  self-center"
          />
        </Fragment>
      ) : (
        <div className="flex-shrink-0 flex flex-col">
          <img
            src={imageUrl}
            className="size-[200px] self-center rounded-full"
          />
          <h1 className="text-white self-center font-bold text-xl">{email}</h1>
          <h1 className="text-gray-300 self-center">@{userName}</h1>
          <div className="flex text-white justify-evenly mt-10"></div>
        </div>
      )}
      <div className="flex justify-evenly text-white">
        {followloading ? (
          <Fragment>
            <div className="h-[40px] bg-gray animate-pulse w-[60px] rounded-full" />
            <div className="h-[40px] bg-gray animate-pulse w-[60px] rounded-full" />
          </Fragment>
        ) : (
          <Fragment>
            <div
              // onClick={(e) => {
              //   dispatch(
              //     setData({
              //       userName,
              //       type: "follower",
              //       data: followers,
              //     })
              //   );

              //   followModalRef.current.showModal();
              // }}
              className="flex flex-col items-center"
            >
              <span className="text-[30px]">{followers?.length}</span>
              <span>{followers.length > 1 ? "listeners" : "listener"}</span>
            </div>

            {/* <div onClick={e => {
              dispatch(setData({
                userName,
                type : "following",
                data : following,
              }))
              followModalRef.current.showModal();
            }} className="flex flex-col items-center">
              <span className="text-[30px]">{following?.length}</span>
              <span>following</span>
            </div> */}

            {myUserID !== userID ? (
              AuthUser ? (
                <button
                  disabled={followed}
                  onClick={Follow}
                  className="rounded-full bg-gray h-fit self-center px-6 py-2"
                >
                  {loading ? (
                    <LoaderCircle className="animate-spin" />
                  ) : followed ? (
                    "subscribed"
                  ) : (
                    "subscribe"
                  )}
                </button>
              ) : (
                <Link
                  to="/login"
                  className="rounded-full self-center px-6 py-2 bg-gray"
                >
                  Subscribe
                </Link>
              )
            ) : null}
          </Fragment>
        )}
      </div>
      <h1 className="text-gray-300 text-xl font-bold mx-4 mt-5">
        user podcast
      </h1>
      <section className="grow min-h-0 gap-4 flex flex-col overflow-scroll scroll-none py-4 px-4">
        {loadingPodcast || loading ? (
          <Fragment>
            <div className="h-[40px] flex-shrink-0 rounded-lg w-full bg-gray animate-pulse" />
            <div className="h-[40px] flex-shrink-0 rounded-lg w-full bg-gray animate-pulse" />
            <div className="h-[40px] flex-shrink-0 rounded-lg w-full bg-gray animate-pulse" />
          </Fragment>
        ) : AuthUser ? (
          data.length === 0 ? (
            <span className="text-gray-600 font-bold text-xl mx-auto text-center">
              user does not
              <br />
              have any podcast
            </span>
          ) : (
            data.map((item) => (
              <PodcastListItem key={item.id} userID={userID} {...item} />
            ))
          )
        ) : (
          <Link
            className="bg-slate-700 p-4 rounded-full self-center my-auto text-white"
            to="/login"
          >
            sign in
          </Link>
        )}
      </section>
      
      <FollowModal modalRef={followModalRef} />
      <AudioPlayer />
    </Fragment>
  );
}

const PodcastListItem = (props) => {
  async function copy() {
    try {
      const domain = window.location.href.split("://")[1].split("/")[0];
      const url = `${
        process.env.NODE_ENV === "development" ? "http" : "https"
      }://${domain}/#/listen/${props.userID}`;
      await navigator.clipboard.writeText(url);
      alert("url copied");
    } catch (err) {
      alert(err?.message);
    }
  }
  const { title, imageUrl, duration } = props;
  const dispatch = useDispatch();
  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Build the time string conditionally based on the hours
    if (hours > 0) {
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(secs).padStart(2, "0")}`;
    } else {
      return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
        2,
        "0"
      )}`;
    }
  }

  return (
    <div
      onClick={(e) => {
        dispatch(
          setCurrentPodCast({
            ...props,
            cover: props.imageUrl,
          })
        );
        dispatch(setAudioPlayerStatus({ status: "fullscreen" }));
      }}
      className="flex  gap-2 bg-gray p-2 rounded-lg"
    >
      <img src={imageUrl || "/default.png"} className="size-16 rounded-md object-cover" />
      <div className="flex flex-col justify-center grow">
        <span className="text-white ">{title}</span>
        <span className="text-gray-300 ">{secondsToHMS(parseInt(duration))}</span>
      </div>
    </div>
  );
};

export default UserProfile;
