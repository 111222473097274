import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

export const fetchFollowerFollowing = createAsyncThunk(
  "fetchUserFollower",
  async function ({ userID }) {
    try {
      const followers = (
        await api.post("/userfollower/get-followers-list", {
          followingID: userID,
        })
      ).data.followers;

      const following = (
        await api.post("/userfollower/get-following-list", {
          followerID: userID,
        })
      ).data.followers;

      return { followers, following };
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUserPodCast = createAsyncThunk(
  "fetchUserProfilePodcast",
  async function ({ userID }) {
    try {
      const podcast = (
        await api.post("/userpodcast/get-user-podcasts", { userID })
      ).data;
      return podcast;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUserProfileData = createAsyncThunk(
  "fetchUserProfileData",
  async function ({ userID }) {
    try {
      const user = (
        await api.post("/user/user-details-by-id", { userID })
      ).data.user[0];
      return user;
    } catch (err) {
      throw err;
    }
  }
);

const ProfileSlice = createSlice({
  name: "user-profile",
  initialState: {
    follow: {
      loading: false,
      error: null,
      followers: [],
      following: [],
    },
    user: {
      userData: {
        imageUrl: "",
        email: "",
        userName: "",
      },
      loading: false,
      error: null,
    },
    podcast: {
      data: [],
      loading: false,
      error: null,
    },
  },
  reducers : {
    addFollower : (state, action) => {
      state.follow.followers = [...state.follow.following, action.payload]
    }
  },
  extraReducers: (builder) => {
    // follows
    builder.addCase(fetchFollowerFollowing.pending, (state, action) => {
      state.follow.loading = true;
      state.follow.error = null;
    });
    builder.addCase(fetchFollowerFollowing.rejected, (state, action) => {
      state.follow.loading = false;
      state.follow.error = action.error.message;
    });
    builder.addCase(fetchFollowerFollowing.fulfilled, (state, action) => {
      state.follow.loading = false;
      state.follow.error = null;
      state.follow.followers = action.payload.followers;
      state.follow.following = action.payload.following;
    });

    // podcast
    builder.addCase(fetchUserPodCast.pending, (state, action) => {
      state.podcast.loading = true;
      state.podcast.error = null;
    });
    builder.addCase(fetchUserPodCast.rejected, (state, action) => {
      state.podcast.loading = false;
      state.podcast.error = action.error.message;
    });
    builder.addCase(fetchUserPodCast.fulfilled, (state, action) => {
      state.podcast.loading = false;
      state.podcast.error = null;
      state.podcast.data = action.payload.filter(item => item.id !== "6b26fbe1-566b-41aa-a19c-5b43efcfaa9c");
    });

    // user
    builder.addCase(fetchUserProfileData.pending, (state, action) => {
      state.user.loading = true;
      state.user.error = null;
    });
    builder.addCase(fetchUserProfileData.rejected, (state, action) => {
      state.user.loading = false;
      state.user.error = action.error.message;
    });
    builder.addCase(fetchUserProfileData.fulfilled, (state, action) => {
      state.user.loading = false;
      state.user.error = null;
      state.user.userData = action.payload;
    });
  },
});

export const {addFollower} = ProfileSlice.actions;
export default ProfileSlice.reducer;
