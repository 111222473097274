import { X } from "lucide-react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentModal } from "../../redux/slice/Appconfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot, faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export default function PaymentModal({ modalRef }) {
  const { paymentModal } = useSelector((state) => state.config);
  const dispatch = useDispatch();
  return (
    <dialog ref={modalRef}>
      <div
        className={`text-white border-2 border-gray flex w-full fixed gap-4 transition-transform flex-col p-8 z-50 bottom-0 left-0 bg-graydark border-r-gray border-r-2 rounded-t-3xl ${
          paymentModal ? "translate-y-0" : "translate-y-[999px]"
        }`}
      >
        <section className="flex mb-6">
          <h1 className="grow text-2xl font-bold">You Subscription</h1>
          <button
            className="outline-none"
            onClick={() => {
              dispatch(setPaymentModal({ open: false }));
              setTimeout(() => modalRef.current.close(), 150);
            }}
          >
            <X />
          </button>
        </section>
        <div className="bg-gray p-4 rounded-xl border-green-300 border-2">
          <div className="flex gap-4 mb-2 items-center">
            <FontAwesomeIcon className="text-green-300" icon={faCircleDot} />
            <span className="text-2xl font-bold grow">Basic</span>
            <span className="text-2xl text-grayExtraLight font-bold">
              (Free)
            </span>
          </div>
          <p className=" ml-4 gap-2 mb-2 flex items-center">
            <FontAwesomeIcon className="text-blue-400" icon={faCircleCheck} />{" "}
            Daily 2 Songs
          </p>
          <p className=" ml-4 gap-2 flex items-center">
            <FontAwesomeIcon className="text-blue-400" icon={faCircleCheck} />{" "}
            Lorem Ipsum doir set
          </p>
        </div>
        <div className="bg-gray p-4 rounded-xl">
          <div className="flex gap-4 mb-2 items-center">
            <FontAwesomeIcon
              className="text-grayExtraLight"
              icon={faCircleDot}
            />
            <span className="text-2xl font-bold grow">Pro</span>
            <span className="text-2xl text-grayExtraLight font-bold">
              5.99$
            </span>
          </div>
          <p className=" ml-4 gap-2 mb-2 flex items-center">
            <FontAwesomeIcon className="text-blue-400" icon={faCircleCheck} />{" "}
            Daily 5 Songs
          </p>
          <p className=" ml-4 gap-2 mb-2 flex items-center">
            <FontAwesomeIcon className="text-blue-400" icon={faCircleCheck} />{" "}
            Lorem Ipsum doir set
          </p>
          <p className=" ml-4 gap-2 flex items-center">
            <FontAwesomeIcon className="text-blue-400" icon={faCircleCheck} />{" "}
            even better
          </p>
        </div>

        <button className="text-xl font-bold bg-gradient-to-r from-[#40c9ff]  to-[#e81cff] py-3 rounded-full">
          upgrade your plan
        </button>
      </div>
    </dialog>
  );
}
