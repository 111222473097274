import { Fragment, useState, useEffect } from "react";
import moment from "moment-timezone";

function CountdownTimer({}) {
  const [countdown, setCountdown] = useState(0);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    // const targetTime = 1728716400;
    const targetTime = 1729148400;
    // const targetTime = moment
    //   .tz("America/Los_Angeles")
    //   .set({ hour: 0, minute: 0, second: 0 })
    //   .add(4, "day")
    //   .unix();


    console.log(targetTime);
    const intervalId = setInterval(() => {
      const remainingTime = targetTime - moment().unix();
      if (remainingTime < 0) {
        setFinished(true);
      }
      setCountdown(remainingTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const days = Math.floor(countdown / (60 * 60 * 24));
  const hours = Math.floor((countdown % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((countdown % (60 * 60)) / 60);
  const seconds = countdown % 60;

  function addZeros(a) {
    return a < 10 ? `0${a}` : a;
  }

  return (
    <div className="flex gap-4 items-center font-thin self-center mt-12">
      {finished ? (
        <div className="text-2xl">
          Event Started !
        </div>
      ) : (
        <Fragment>
          <div className="flex flex-col items-center">
            <span className="text-[30px] md:text-[50px]">{days}</span>
            <span className="text-xs md:text-base">Days</span>
          </div>
          <span className="text-[15px] md:text-[30px] text-gray-400 mb-5">
            :
          </span>
          <div className="flex flex-col items-center">
            <span className="text-[30px] md:text-[50px]">
              {addZeros(hours)}
            </span>
            <span className="text-xs md:text-base">Hours</span>
          </div>
          <span className="text-[15px] md:text-[30px] text-gray-400 mb-5">
            :
          </span>
          <div className="flex flex-col items-center">
            <span className="text-[30px] md:text-[50px]">
              {addZeros(minutes)}
            </span>
            <span className="text-xs md:text-base">Minutes</span>
          </div>
          <span className="text-[15px] md:text-[30px] text-gray-400 mb-5">
            :
          </span>
          <div className="flex flex-col items-center">
            <span className="text-[30px] md:text-[50px]">
              {addZeros(seconds)}
            </span>
            <span className="text-xs md:text-base">Seconds</span>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default CountdownTimer;
