import React, { Fragment, useRef } from "react";
import Navbar, { SkeletonNavbar } from "../../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "lucide-react";
import FollowModal from "../../components/modal/followModal";
import PaymentModal from "../../components/modal/PaymentModal";
import { setData } from "../../redux/slice/follow/userFollowPreview";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { setPaymentModal } from "../../redux/slice/Appconfig";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

function SettingsPage() {
  const { user } = useSelector((state) => state.auth);
  const { following, followers, loading, error } = useSelector(
    (state) => state.profile.follow
  );
  const dispatch = useDispatch();
  const followModalRef = useRef();
  const paymentModalRef = useRef();

  function showFollowing() {
    dispatch(
      setData({ data: following, type: "following", userName: user.userName })
    );
    followModalRef.current.showModal();
  }

  function showFollowers() {
    dispatch(
      setData({ data: followers, type: "follower", userName: user.userName })
    );
    followModalRef.current.showModal();
  }

  function showPaymentModal(){
    paymentModalRef.current.showModal();
    dispatch(setPaymentModal({ open: true }));
  }

  return (
    <Fragment>
      <Navbar />
      <h1 className="text-4xl mt-6">settings</h1>

      <Fragment>
        {loading ? (
          <Fragment>
            <div className="h-12 animate-pulse bg-graydark mt-3 rounded-lg" />
            <div className="h-12 animate-pulse bg-graydark mt-3 rounded-lg" />
          </Fragment>
        ) : (
          <Fragment>
            <div
              onClick={showFollowing}
              className="flex gap-4 p-4 bg-graydark mt-3 rounded-lg"
            >
              <span className="grow">My Subscriptions</span>
              <span>{following.length}</span>
              <ChevronRight />
            </div>
            <div
              onClick={showFollowers}
              className="flex gap-4 p-4 bg-graydark mt-3 rounded-lg"
            >
              <span className="grow">My Listeners</span>
              <span>{followers.length}</span>
              <ChevronRight />
            </div>
            <div
              onClick={showPaymentModal}
              className="flex gap-4 p-4 bg-graydark mt-3 rounded-lg"
            >
              <span className="grow">Current Plan</span>
              <span className="bg-green-300 text-black px-2 py-1 rounded-full text-sm">
                Free
              </span>
              <ChevronRight />
            </div>
          </Fragment>
        )}
      </Fragment>

      <FollowModal modalRef={followModalRef} />
      <PaymentModal modalRef={paymentModalRef} />
      {/* <Elements stripe={stripePromise}> */}
      {/* </Elements> */}
    </Fragment>
  );
}

export default SettingsPage;
