import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

export const fetchFollowerFollowing = createAsyncThunk(
  "fetchFollower",
  async function ({ userID }) {
    console.log("fetching user followers");
    try {
      const followers = (
        await api.post("/userfollower/get-followers-list", {
          followingID: userID,
        })
      ).data.followers;

      const following = (
        await api.post("/userfollower/get-following-list", {
          followerID: userID,
        })
      ).data.followers;

      return { followers, following, userID };
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUserPodCast = createAsyncThunk(
  "fetchUserPodcast",
  async function ({ userID }) {
    try {
      const podcast = (
        await api.post("/userpodcast/get-user-podcasts", { userID })
      ).data;
      return { podcast, userID };
    } catch (err) {
      throw err;
    }
  }
);

const ProfileSlice = createSlice({
  name: "profile",
  initialState: {
    follow: {
      loading: false,
      error: null,
      followers: 0,
      following: 0,
    },
    id: "",
    podcast: {
      data: [],
      loading: false,
      error: null,
    },
  },
  reducers: {
    setID: function (state, action) {
      state.id = action.payload.id;
    },

    clearProfile: function (state, action) {
      state.follow.loading = false;
      state.follow.error = null;
      state.follow.followers = 0;
      state.follow.following = 0;

      state.id = "";

      state.podcast.loading = false;
      state.podcast.error = null;
      state.podcast.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFollowerFollowing.pending, (state, action) => {
      state.follow.loading = true;
      state.follow.error = null;
    });
    builder.addCase(fetchFollowerFollowing.rejected, (state, action) => {
      state.follow.loading = false;
      state.follow.error = action.error.message;
    });
    builder.addCase(fetchFollowerFollowing.fulfilled, (state, action) => {
      state.follow.loading = false;
      state.follow.error = null;
      state.follow.followers = action.payload.followers;
      state.follow.following = action.payload.following;
      state.id = action.payload.userID;
    });

    builder.addCase(fetchUserPodCast.pending, (state, action) => {
      state.podcast.loading = true;
      state.podcast.error = null;
    });
    builder.addCase(fetchUserPodCast.rejected, (state, action) => {
      state.podcast.loading = false;
      state.podcast.error = action.error.message;
    });
    builder.addCase(fetchUserPodCast.fulfilled, (state, action) => {
      state.podcast.loading = false;
      state.podcast.error = null;
      state.podcast.data = action.payload.podcast.filter(
        (item) => item.id !== "6b26fbe1-566b-41aa-a19c-5b43efcfaa9c"
      );
      state.id = action.payload.userID;
    });
  },
});
export const { clearProfile , setID} = ProfileSlice.actions;
export default ProfileSlice.reducer;
