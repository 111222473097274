import axios from "axios";

export async function notify({ email, name }) {
  try {
    const notification = await axios.post(
      "https://srhe29trb2.execute-api.us-east-2.amazonaws.com/dev/user/send-apn-notification-new-app",
      {
        deviceToken: process.env.REACT_APP_DEVICETOKEN,
        title: "user logged in",
        body: `${name} with email ${email} just logged in!`,
        data: {
          screen: "Details",
          params: {
            id: 123,
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`
        },
      }
    );

    console.log("notified harshit");
  } catch (err) {
    throw err;
  }
}
