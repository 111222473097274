import React, { Fragment } from "react";
import { Music , UserPlus, X } from "lucide-react";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Card from "../../components/Card";

const Home = () => {
  const { podcasts, loading, error, filterApplied, filterData } = useSelector((state) => state.discover);
  const dataToBeDisplayed = filterApplied ? filterData : podcasts;
  return (
    <Fragment>
      <Navbar nav search/>
      <div className="overflow-scroll grow flex flex-col mt-6 mb-3 scroll-none">
        <h1 className="text-white mb-3 font-bold text-3xl">
          to be live soon
        </h1>
        <section className="flex text-graylight justify-center items-center text-[40px] grow my-4 gap-4">
          {loading
            ? [1, 1, 1, 1].map((item, index) => (
                <div key={index} className="flex flex-col gap-4">
                  <div className="h-[150px] rounded-lg bg-gray-800 animate-pulse" />
                  <div className="flex gap-4">
                    <div className="size-10 rounded-full bg-gray-800 animate-pulse" />
                    <div className="grow my-2 rounded-full bg-gray-800 animate-pulse" />
                  </div>
                </div>
              ))
            : "to be live soon"}
        </section>
      </div>

      <Link to="/create" className="text-2xl rounded-full bg-gradient-to-r from-[#40c9ff] to-[#e81cff] mt-auto text-white justify-center py-4 flex gap-2 items-center">
        <Music size={20} />
        create
      </Link>
    </Fragment>
  );
};

export default Home;
