import { useSelector } from "react-redux";
import { X } from "lucide-react";
import { Link } from "react-router-dom";

function FollowModal({ modalRef }) {
  const { data, type, userName } = useSelector(
    (state) => state.currentFollowPreview
  );
  return (
    <dialog ref={modalRef} className="bg-transparent">
      <div className="flex relative rounded-xl flex-col bg-gray py-4 px-6 text-white gap-4">
        <div className="flex gap-4 items-center">
          <h1 className="font-bold">
            {type === "follower"
              ? `You current listeners`
              : `Your Subscription list`}
          </h1>
          <button onClick={(e) => modalRef.current.close()}>
            <X />
          </button>
        </div>
        <h1 className="font-bold">
          {type === "follower"
            ? `${data.length} listener${data.length > 1 ? 's' : ''}`
            : `${data.length} subscription${data.length > 1 ? 's' : ''}`}
        </h1>
        <section className="flex flex-col gap-4">
          {data.map((item) => {
            return (
              <Link
                to={`/user/${
                  type === "follower" ? item.followerID : item.followingID
                }`}
                className="flex item-center items-center gap-4"
                key={item.id}
              >
                <img
                  onError={(e) => (e.target.src = "/avatar.png")}
                  className="size-10 rounded-full"
                  src={
                    type === "follower"
                      ? item.followerUserProfile
                      : item.followingUserProfile
                  }
                />
                <span>
                  {type === "follower"
                    ? item.followerUserName
                    : item.followingUserName}
                </span>
              </Link>
            );
          })}
        </section>
      </div>
    </dialog>
  );
}

export default FollowModal;
