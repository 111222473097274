import React from "react";

function Privacy() {
  return (
    <div className='overflow-y-auto scroll-none'>
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p>
          This Privacy Policy explains how we collect, use, and share your
          personal information when you use our AI podcast generation app
          ("App"). By using the App, you agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          2. Information We Collect
        </h2>
        <p className="mb-2">
          <strong>Account Information:</strong> When you sign in to the App
          using Google or Apple, we collect basic information associated with
          your account, such as your email address, name, and profile picture.
          We do not collect or store your password as authentication is handled
          by Google and Apple.
        </p>
        <p className="mb-2">
          <strong>Voice Uploads:</strong> You may upload a sample voice
          recording to the App. This data is stored securely and used to enhance
          your experience within the App.
        </p>
        <p>
          <strong>Profile Updates:</strong> You can update your profile
          information, including changing your profile picture. Any profile
          changes you make are stored securely.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          3. How We Use Your Information
        </h2>
        <p className="mb-2">
          <strong>To Provide and Improve the App:</strong> We use your
          information to authenticate you, personalize your experience, and
          improve the functionality of the App.
        </p>
        <p>
          <strong>Communication:</strong> We may use your email address to send
          you important updates or information regarding your account and the
          App.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          4. Sharing of Your Information
        </h2>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. We may share your information with service providers who
          assist us in operating the App, but these third parties are obligated
          to keep your information confidential.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">5. Security</h2>
        <p>
          We take reasonable steps to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          electronic transmission or storage is 100% secure, and we cannot
          guarantee absolute security.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
        <p className="mb-2">
          <strong>Access and Update:</strong> You can access and update your
          account information at any time through the App.
        </p>
        <p>
          <strong>Account Deletion:</strong> You can request to delete your
          account and associated data by contacting us. We will process such
          requests in accordance with applicable laws.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">
          7. Changes to This Policy
        </h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting the updated policy within
          the App or through other communication channels.
        </p>
      </section>
    </div>
  );
}

export default Privacy;
