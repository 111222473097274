import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slice/Auth";
import { LogOut, X } from "lucide-react";
import { setSideBar } from "../redux/slice/Appconfig";
import SkeletonSideBar from "../pages/skeleton/skeleTonSideBar";
import { clearAuth } from "../redux/slice/Auth";
import { clearProfile } from "../redux/slice/profile";
import { clearChat } from "../redux/slice/chat";

function SideBar() {
  const { sideBar } = useSelector((state) => state.config);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);

  let imageUrl;
  let email;
  let userName;

  if (loading) {
    return (
      <SkeletonSideBar
        sideBar={sideBar}
        onClose={(e) => dispatch(setSideBar({ open: false }))}
      />
    );
  }

  if (!user) {
    return (
      <nav
        className={`text-white flex fixed gap-4 w-[350px] transition-transform flex-col p-4 z-50 top-0 left-0 bg-graydark border-r-gray-700 border-r-2 h-[100dvh] ${
          sideBar ? "translate-x-0" : "translate-x-[-999px]"
        }`}
      >
        <button
          onClick={(e) => dispatch(setSideBar({ open: false }))}
          className="absolute right-4 top-4"
        >
          <X />
        </button>
        <div className="gap-4 flex flex-col my-auto">
          <Link
            onClick={(e) => dispatch(setSideBar({ open: false }))}
            className="p-4 hover:bg-gray-600 bg-gray-700 rounded-full text-lg font-bold"
            to="/login"
          >
            discover
          </Link>
          <Link
            onClick={(e) => dispatch(setSideBar({ open: false }))}
            className="p-4 hover:bg-gray-600 bg-gray-700 rounded-full text-lg font-bold"
            to="/login"
          >
            create
          </Link>
          <Link
            onClick={(e) => dispatch(setSideBar({ open: false }))}
            className="p-4 hover:bg-gray-600 bg-gray-700 rounded-full text-lg font-bold"
            to="/login"
          >
            your channel
          </Link>
          <Link
            onClick={(e) => dispatch(setSideBar({ open: false }))}
            className="p-4 hover:bg-gray-600 bg-gray-700 rounded-full text-lg font-bold"
            to="/login"
          >
            settings
          </Link>
        </div>
        <button
          className="flex items-center gap-2 text-xl self-center p-4 bg-gray-900 rounded-full px-6"
          onClick={(e) => {
            navigate("/login");
          }}
        >
          Sign in
        </button>
      </nav>
    );
  }

  // display them proper user

  imageUrl = user.imageUrl;
  email = user.email;
  userName = user.userName;

  return (
    <nav
      className={`text-white flex max-w-[350px] w-[100dvw] fixed gap-4 transition-transform flex-col px-4 pt-14 pb-4 z-50 top-0 left-0 bg-graydark border-r-gray border-r-2 h-[100dvh] ${
        sideBar ? "translate-x-0" : "translate-x-[-999px]"
      }`}
    >
      <button
        onClick={(e) => dispatch(setSideBar({ open: false }))}
        className="absolute right-4 top-4"
      >
        <X />
      </button>
      <img src={imageUrl} className="size-14 rounded-full" />
      <h2 className="text-white text-lg">{email}</h2>
      <h2 className="text-gray-200 text-lg ">@{userName.replace(/ /g, "").toLowerCase()}</h2>

      <div className="gap-4 flex flex-col my-auto">
        <Link
          onClick={(e) => dispatch(setSideBar({ open: false }))}
          className="px-4 py-2 hover:bg-graylight bg-gray rounded-full text-lg"
          to="/home"
        >
          discover
        </Link>
        <Link
          onClick={(e) => dispatch(setSideBar({ open: false }))}
          className="px-4 py-2 hover:bg-graylight bg-gray rounded-full text-lg"
          to="/create"
        >
          create
        </Link>

        <Link
          onClick={(e) => dispatch(setSideBar({ open: false }))}
          className="px-4 py-2 hover:bg-graylight bg-gray rounded-full text-lg"
          to="/profile"
        >
          your channel
          </Link>
          
        <Link
          onClick={(e) => dispatch(setSideBar({ open: false }))}
          className="px-4 py-2 hover:bg-graylight bg-gray rounded-full text-lg"
          to="/settings"
        >
          settings
          </Link>
      </div>
      <button
        className="flex items-center gap-2 justify-center text-lg p-4 mb-4 bg-gray rounded-full px-6"
        onClick={(e) => {
          dispatch(
            logout(() => {
              localStorage.clear();
              dispatch(clearAuth());
              dispatch(clearChat())
              dispatch(clearProfile());
              dispatch(setSideBar({ open: false }));
              navigate("/login");
            })
          );
        }}
      >
        <LogOut size={20}/>
        Logout
      </button>

      <p className="text-sm text-center">
        Podspin
        {/* Popfeedapp © 2024, Harshit Foundation, 
        All Right Reserved. */}
        <br />
        <br />
        <Link className="underline" to="/terms">
              Terms
            </Link> | <Link className="underline" to="/privacy">
              Privacy Policy
            </Link>
      </p>
    </nav>
  );
}

export default SideBar;
