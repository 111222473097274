import {Fragment} from "react";

function SkeletonSharablePodcast() {
  return (
    <Fragment>
      <div className="size-10 rounded-full bg-gray-700 animate-pulse" />
      <div className="size-10 grow w-full mt-4 rounded-lg bg-gray-700 animate-pulse" />
    </Fragment>
  );
}

export default SkeletonSharablePodcast;
