import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchPodcastFromId } from "../../../utils/api-helper";
export const fetchPodcast = createAsyncThunk(
  "fetchPodcast",
  fetchPodcastFromId
);


const shareblePodcast = createSlice({
  name: "sharablePodcast",
  initialState: {
    podcast: {
      url: "",
      duration: 0,
      progress: 0,
      id: "",
      title: "",
      cover: "",
      isPlaying: false,
      userID : ""
    },
    loading: true,
    error: null,
  },
  reducers: {
    setProgress: (state, action) => {
      state.podcast.progress = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.podcast.isPlaying = action.payload;
    },

    setDuration : (state, action) => {
      state.podcast.duration = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPodcast.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPodcast.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(fetchPodcast.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.podcast.cover = action.payload.imageUrl;
      state.podcast.duration = action.payload.duration;
      state.podcast.id = action.payload.id;
      state.podcast.url = action.payload.podcastUrl;
      state.podcast.title = action.payload.title;
      state.podcast.userID = action.payload.hostId;
    });
  },
});

export const {setProgress, setIsPlaying, setDuration} = shareblePodcast.actions;
export default shareblePodcast.reducer;