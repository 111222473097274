import {Fragment} from 'react';
const Terms = () => {
  return (
    <div className='overflow-y-auto scroll-none'>
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p>
          These Terms of Service ("Terms") govern your use of our AI podcast generation app ("App"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">2. Your Rights and Responsibilities</h2>
        <p className="mb-2">
          <strong>User Content:</strong> You grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sub-licensable license to use, reproduce, modify, adapt, publish, perform, display, distribute, and create derivative works of your voice recordings and other user content you provide to the App.
        </p>
        <p className="mb-2">
          <strong>Privacy:</strong> Your privacy is important to us. We collect and use your information in accordance with our Privacy Policy.
        </p>
        <p>
          <strong>Acceptable Use:</strong> You agree not to use the App for any illegal or unauthorized purpose. You are responsible for maintaining the confidentiality of your account and password.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">3. Our Rights and Responsibilities</h2>
        <p className="mb-2">
          <strong>App Modification:</strong> We may modify or discontinue the App at any time without notice.
        </p>
        <p className="mb-2">
          <strong>Intellectual Property:</strong> The App and its content are protected by intellectual property laws. You may not use our intellectual property without our written permission.
        </p>
        <p>
          <strong>Third-Party Services:</strong> We may integrate third-party services into the App. Your use of these services is subject to their terms and conditions.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">4. Disclaimer of Warranties</h2>
        <p>
          THE APP IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
        <p>
          IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR IN CONNECTION WITH THE USE OR INABILITY TO USE THE APP.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">6. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these Terms shall be submitted to the exclusive jurisdiction of the courts of [Your Jurisdiction].
        </p>
      </section>
    </div>
  );
};

export default Terms;
