import React, { Fragment, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";
import {
  LoaderCircle,
  Music,
  EllipsisVertical,
  Share2,
  LogOut,
  X,
} from "lucide-react";
import { uploadFileOnS3 } from "../../utils/aws";
import api from "../../utils/api";
import { updateUser } from "../../redux/slice/Auth";
import { useDispatch } from "react-redux";
import { setAudioPlayerStatus } from "../../redux/slice/Appconfig";
import { setCurrentPodCast } from "../../redux/slice/podcast/currentPlaingPodcast";
import AudioPlayer from "../../components/AudioPlayer";
import { setData } from "../../redux/slice/follow/userFollowPreview";
import FollowModal from "../../components/modal/followModal";

import { Link } from "react-router-dom";

import { logout, clearAuth } from "../../redux/slice/Auth";
import { clearChat } from "../../redux/slice/chat";
import { clearProfile } from "../../redux/slice/profile";
import { setSideBar } from "../../redux/slice/Appconfig";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { follow, podcast } = useSelector((state) => state.profile);
  const { following, followers, loading: followloading, error } = follow;
  const { data, error: errorPodcast, loading: loadingPodcast } = podcast;
  const [open, setopen] = useState();
  const { imageUrl, email, userName, id } = useSelector(
    (state) => state.auth.user
  );
  const [update, setUpdate] = useState({
    imageUrl,
    email,
    userName,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const followModalRef = useRef();
  const navigate = useNavigate();
  const handleImageInput = async (e) => {
    setLoading(true);
    if (e.target.files && e.target.files[0]) {
      // get the url
      const url = await uploadFileOnS3(
        e.target.files[0],
        process.env.REACT_APP_BUCKET_NAME
      );
      // upload image
      console.log(url);
      setUpdate({
        imageUrl: url,
      });
      await saveUser({
        imageUrl: url,
      });
      setLoading(false);
    }
  };

  async function saveUser(updateData) {
    try {
      const payload = {
        id,
        userData: updateData,
      };
      const res = await api.post("/user/update-profile", payload);
      console.log(res);
      dispatch(updateUser({ data: updateData }));
      console.log("user profile updated");
    } catch (err) {
      alert(err?.message);
      console.log(err);
    }
  }

  async function handleCopy() {
    try {
      await navigator.clipboard.writeText(`https://podspin.com/#/user/${id}`);
      alert("url copied successfuly");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Fragment>
      <header className="relative flex justify-between">
        <Navbar />
        <button
          onClick={(e) => setopen(!open)}
          className="bg-gray p-3 rounded-full"
        >
          {open ? <X size={20} /> : <EllipsisVertical size={20} />}
        </button>

        <div
          style={{ display: open ? "flex" : "none" }}
          className="absolute divide-y-[2px] divide-grayExtraLight flex-col rounded-md px-4 py-2 rounded-mg border border-graylight right-12 top-0 text-white bg-gray"
        >
          <span onClick={handleCopy} className="cursor-pointer py-2 flex gap-2 items-center text-sm">
            <Share2 size={18} />
            share profile
          </span>
          <span
            onClick={(e) => {
              dispatch(
                logout(() => {
                  localStorage.clear();
                  dispatch(clearAuth());
                  dispatch(clearChat());
                  dispatch(clearProfile());
                  dispatch(setSideBar({ open: false }));
                  navigate("/login");
                })
              );
            }}
            className="cursor-pointer py-2 flex gap-2 text-red-400 items-center text-sm"
          >
            <LogOut size={18} />
            Logout
          </span>
        </div>
      </header>
      <div className="flex-shrink-0 flex flex-col">
        <label
          htmlFor="image"
          className="size-[200px] rounded-full mb-6 bg-gray  self-center flex justify-center items-center"
        >
          {loading ? (
            <LoaderCircle className="text-white animate-spin" />
          ) : (
            <img
              src={update.imageUrl}
              className="size-full self-center object-cover rounded-full"
            />
          )}
        </label>
        <input
          id={"image"}
          type="file"
          accept="image/"
          className="absolute top-[-9999px]"
          onChange={handleImageInput}
        />
        <h1 className="text-white self-center font-bold text-xl">{email}</h1>
        <h1 className="text-gray-300 self-center">@{userName}</h1>
        <div className="flex text-white justify-evenly mt-10">
          {followloading ? (
            <Fragment>
              <div className="h-[40px] bg-gray animate-pulse w-[60px] rounded-full" />
              <div className="h-[40px] bg-gray animate-pulse w-[60px] rounded-full" />
            </Fragment>
          ) : (
            <Fragment>
              <div
                onClick={(e) => {
                  dispatch(
                    setData({
                      userName,
                      type: "follower",
                      data: followers,
                    })
                  );

                  followModalRef.current.showModal();
                }}
                className="flex flex-col items-center"
              >
                <span className="text-[30px]">{followers?.length}</span>
                <span>listeners</span>
              </div>

              {/* <div onClick={e => {
              dispatch(setData({
                userName,
                type : "following",
                data : following,
              }))
              followModalRef.current.showModal();
            }} className="flex flex-col items-center">
              <span className="text-[30px]">{following?.length}</span>
              <span>stream</span>
            </div> */}
            </Fragment>
          )}
        </div>
      </div>
      <h1 className="text-gray-300 text-xl font-bold mx-4 mt-5">
        user podcasts
      </h1>
      <section className="grow min-h-0 gap-4 flex flex-col overflow-scroll scroll-none py-4 px-4">
        {loadingPodcast ? (
          <Fragment>
            <div className="h-[40px] flex-shrink-0 rounded-lg w-full bg-gray animate-pulse" />
            <div className="h-[40px] flex-shrink-0 rounded-lg w-full bg-gray animate-pulse" />
            <div className="h-[40px] flex-shrink-0 rounded-lg w-full bg-gray animate-pulse" />
          </Fragment>
        ) : data.length === 0 ? (
          <div className="text-xl mx-auto grow px-auto justify-center text-center flex flex-col">
            <span>create your podcast</span>
            <Link
              to="/create"
              className="flex gap-3 items-center my-6 bg-white text-black py-4 px-6 self-center rounded-full"
            >
              <Music />
              create
            </Link>
          </div>
        ) : (
          data.map((item) => (
            <PodcastListItem key={item.id} userID={id} {...item} />
          ))
        )}
      </section>
      <FollowModal modalRef={followModalRef} />
      <AudioPlayer />
    </Fragment>
  );
}

const PodcastListItem = (props) => {
  async function copy() {
    try {
      const domain = window.location.href.split("://")[1].split("/")[0];
      const url = `${
        process.env.NODE_ENV === "development" ? "http" : "https"
      }://${domain}/#/listen/${props.userID}`;
      await navigator.clipboard.writeText(url);
      alert("url copied");
    } catch (err) {
      alert(err?.message);
    }
  }
  const { title, imageUrl, duration } = props;
  const dispatch = useDispatch();
  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Build the time string conditionally based on the hours
    if (hours > 0) {
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(secs).padStart(2, "0")}`;
    } else {
      return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
        2,
        "0"
      )}`;
    }
  }

  return (
    <div
      onClick={(e) => {
        dispatch(
          setCurrentPodCast({
            ...props,
            cover: props.imageUrl,
          })
        );
        dispatch(setAudioPlayerStatus({ status: "fullscreen" }));
      }}
      className="flex  gap-2 bg-gray p-2 rounded-lg"
    >
      <img
        src={imageUrl || "/default.png"}
        className="size-16 rounded-md object-cover"
      />
      <div className="flex flex-col justify-center grow">
        <span className="text-white ">{title}</span>
        <span className="text-gray-300 ">
          {secondsToHMS(parseInt(duration))}
        </span>
      </div>
    </div>
  );
};

export default Profile;

// bf155468-fc75-43c5-8c1f-820400c04de9