import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "../../firebase/init";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  OAuthProvider,
  TwitterAuthProvider
} from "firebase/auth";


export const googleLogin = createAsyncThunk(
  "google-login",
  async function (cb) {
    try {
      const authProvider = new GoogleAuthProvider();
      authProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      authProvider.addScope("https://www.googleapis.com/auth/userinfo.email");

      const result = await signInWithPopup(auth, authProvider);

      const { displayName, email, photoURL, uid } = result.user;
      const extraInfo = await cb({
        uid,
        displayName,
        email,
        photoURL,
        provider: result.providerId,
      });
      return {
        ...extraInfo,
        provider: result.providerId,
      };
    } catch (error) {
      const errorMessage = error.message;
      throw new Error(errorMessage);
    }
  }
);

export const Applelogin = createAsyncThunk("apple-login", async function (cb) {
  try {
    const provider = new OAuthProvider("apple.com");

    provider.addScope("email");
    provider.addScope("name");

    const result = await signInWithPopup(auth, provider);
    const { displayName, photoURL, email, uid } = result.user;
    const extraInfo = await cb({
      uid,
      displayName,
      email,
      photoURL,
      provider: result.providerId,
    });
    return {
      ...extraInfo,
      provider: result.providerId,
    };
  } catch (error) {
    const errorMessage = error.message;
    throw new Error(errorMessage);
  }
});

export const TwiiterLogin = createAsyncThunk('twitter-login', async function(cb){
  try{
    const provider = new TwitterAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log(user)
    return null
  }
  catch(error){
    const errorMessage = error.message;
    console.log(errorMessage)
    throw new Error(errorMessage);
  
  }
})

export const logout = createAsyncThunk("sign-out", async function (cb) {
  try {
    await signOut(auth);
    cb();
    return;
  } catch (error) {
    const errorMessage = error.message;
    throw new Error(errorMessage);
  }
});

const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    user: null,
    loading: true,
    error: null,
    isLoggedIn: true,
    mode: "auto",
  },
  reducers: {
    clearAuth: (state, action) => {
      state.user = null;
      state.loading = true;
      state.error = null;
      state.isLoggedIn = true;
      state.mode = "auto";
    },
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.loading = false;
    },
    updateUser: (state, action) => {
      const payload = action.payload.data;
      state.user = { ...state.user, ...payload };
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(googleLogin.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(googleLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.user = null;
    });
    builder.addCase(googleLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.user = action.payload;
    });
    builder.addCase(Applelogin.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Applelogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.user = null;
    });
    builder.addCase(Applelogin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.user = action.payload;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.user = null;
      state.isLoggedIn = false;
    });
  },
});

export default AuthSlice.reducer;
export const { setLoading, setError, setUser, updateUser, setMode, clearAuth } =
  AuthSlice.actions;
