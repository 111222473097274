import React, { act, Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import SideBar from "../../components/SideBar";
import { StepBack, StepForward, Play, Pause } from "lucide-react";
import SkeletonSharablePodcast from "../skeleton/skeletonSharablePodcast";
import {
  fetchPodcast,
  setIsPlaying,
  setProgress,
  setDuration
} from "../../redux/slice/podcast/sharablePodcast";
import api from "../../utils/api";

function Listen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const playerRef = useRef();
  const { loading, error, podcast } = useSelector(
    (state) => state.sharablePodcast
  );
  useEffect(() => {
    dispatch(fetchPodcast(id));
  }, []);

  const {
    url,
    duration,
    progress,
    title,
    cover,
    isPlaying,
    userID,
  } = podcast;

  const pod = podcast;

  function playAudio() {
    if (playerRef.current) {
      playerRef.current.play();
      dispatch(setIsPlaying(true));
    }
  }
  function stopAudio() {
    if (playerRef.current) {
      playerRef.current.pause();
      dispatch(setIsPlaying(false));
    }
  }

  const handleStepForward = () => {
    if (!playerRef.current) return;
    const newTime = Math.min(progress + 20, duration);
    playerRef.current.currentTime = newTime;
    dispatch(setProgress(newTime));
  };

  const handleStepBack = () => {
    if (!playerRef.current) return;
    const newTime = Math.max(progress - 20, 0);
    playerRef.current.currentTime = newTime;
    dispatch(setProgress(newTime));
  };

  function seekto(time) {
    playerRef.current.currentTime = time;
    dispatch(setProgress(time));
  }

  if (loading) {
    return <SkeletonSharablePodcast />;
  }

  return (
    <Fragment>
      <SideBar />
      <Navbar />

      <div className="bg-[#333333bb] mt-4 size-full flex justify-start flex-col p-6 gap-4 backdrop-blur-2xl rounded-xl overflow-hidden">
        <div className="my-auto flex flex-col gap-4 items-center">
          <img src={cover || '/default.png'} className="rounded-3xl self-center" />
          <h1 className="text-4xl  text-gray-200 ">{title}</h1>
          <Link to={`/user/${userID}`} className="text-2xl  text-gray-500 ">
            Podspin Beta
          </Link>
        </div>
        <section className="justify-evenly items-center mt-auto text-gray-100 flex">
          <StepBack onClick={handleStepBack} size={30} />
          {isPlaying ? (
            <Pause onClick={stopAudio} size={30} />
          ) : (
            <Play onClick={playAudio} size={30} />
          )}
          <StepForward onClick={handleStepForward} size={30} />
        </section>
        <input
          type="range"
          min={0}
          max={duration}
          className="w-full"
          value={progress}
          onChange={(e) => seekto(e.target.value)}
        />
      </div>

      <audio
        ref={playerRef}
        onLoadedMetadata={e => {
          const actualDuration = e.target.duration;
          console.log(actualDuration, duration)
          if (actualDuration !== duration){
            dispatch(setDuration(actualDuration))
            api.post("/userpodcast/update-podcast-data", {
              id,
              userPodcastsData : {
                duration : actualDuration
              }
            })

          }
        }}
        onTimeUpdate={(e) => {
          const currentTime = e.target.currentTime;
          dispatch(setProgress(currentTime));
        }}
        src={url}
      />
    </Fragment>
  );
}

export default Listen;
// 7f5b89f3-a080-4c01-95f3-14d9a182799b
