import { createSlice } from "@reduxjs/toolkit";

const AppConfigSlice = createSlice({
  name: "AppConfig",
  initialState: {
    sideBar: false,
    audioPlayerStatus: "closed",
    firstTimePodcast: JSON.parse(localStorage.getItem("firsttime")) ?? false,
    paymentModal: false,
  },
  reducers: {
    setSideBar: function (state, action) {
      state.sideBar = action.payload.open;
    },
    setPaymentModal: function (state, action) {
      state.paymentModal = action.payload.open;
    },
    setAudioPlayerStatus: function (state, action) {
      state.audioPlayerStatus = action.payload.status;
    },
    setFirstTimePodcast: function (state, action) {
      state.firstTimePodcast = action.payload.data;

      // Add 9 minutes to the current time
      const futureTime = new Date(Date.now() + 9 * 60 * 1000);
      const formattedTime = Date.parse(futureTime);

      console.log(formattedTime);
      localStorage.setItem("timeout", formattedTime);
      localStorage.setItem("firsttime", true);
    },
    clearFirstTimePodcast: function (state, action) {
      state.firstTimePodcast = false;
      localStorage.setItem("timeout", null);
      localStorage.setItem("firsttime", null);
    },
  },
});

export const {
  setSideBar,
  setAudioPlayerStatus,
  setFirstTimePodcast,
  clearFirstTimePodcast,
  setPaymentModal,
} = AppConfigSlice.actions;
export default AppConfigSlice.reducer;
