import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// export const syncChat = createAsyncThunk("syncchat", async function (url) {
//   try {
//     const res = await axios.get(url);
//     return res.data;
//   } catch (err) {
//     throw new Error(err?.message);
//   }
// });

const ChatSlice = createSlice({
  name: "chat",
  initialState: {
    chat: JSON.parse(localStorage.getItem("chat")) || [],
    chatloading: true,
    chaterror: null,
    chatState: "chat",
  },
  reducers: {
    addChat: function (state, action) {
      state.chat = [...state.chat, action.payload];
      localStorage.setItem("chat", JSON.stringify(state.chat));
    },
    clearChat: function (state, action) {
      state.chat = [];
      localStorage.removeItem(
        "chat"
      )
    },
    setState: function (state, action) {
      state.chatState = action.payload.state;
    },
  },

  // extraReducers: (builder) => {
  //   builder.addCase(syncChat.pending, (state, action) => {
  //     state.chatloading = true;
  //     state.chaterror = null;
  //   });
  //   builder.addCase(syncChat.rejected, (state, action) => {
  //     state.chatloading = false;
  //     state.chaterror = action.error.message;
  //   });
  //   builder.addCase(syncChat.fulfilled, (state, action) => {
  //     state.chatloading = false;
  //     state.chaterror = null;
  //     state.chat = action.payload;
  //     console.log(action.payload);
  //   });
  // },
});

export const { addChat, setState, clearChat } = ChatSlice.actions;
export default ChatSlice.reducer;
