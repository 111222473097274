import S3 from "aws-sdk/clients/s3";
const s3 = new S3({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
export async function uploadFileOnS3(file, bucket) {
  const REGION = process.env.REACT_APP_REGION;

  const params = {
    Bucket: bucket,
    Key: file.name,
    Body: file,
  };
  try {
    const upload = await s3.putObject(params).promise();
    const encodedFileName = encodeURIComponent(file.name);
    const fileUrl = `https://${bucket}.s3.${REGION}.amazonaws.com/${encodedFileName}`;
    return fileUrl;
  } catch (error) {
    throw error;
  }
}

export async function fetchdatafroms3(bucketName, file) {
  const REGION = "eu-north-1";
  const params = {
    Bucket: bucketName,
    Key: file,
  };

  const s3Client = new S3({
    region: REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  try {
    const data = await s3Client.getObject(params).promise();
    const jsonData = JSON.parse(data.Body.toString("utf-8")); // Parse the JSON content
    return jsonData;
  } catch (error) {
    throw error;
  }
}

export async function uploadJsonToS3(bucketName, file, content) {
  const REGION = "eu-north-1";

  const params = {
    Bucket: bucketName,
    Key: file,
    Body: JSON.stringify(content),
    ContentType: "application/json",
  };

  const s3Client = new S3({
    region: REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  try {
    const upload = await s3Client.putObject(params).promise();
  } catch (error) {
    throw error;
  }
}

export const uploadBlobToS3 = async ({
  audioURL,
  bucketName,
  name,
  prefix = "",
}) => {
  if (!audioURL) return;

  const audioBlob = await fetch(audioURL).then((response) => response.blob());
  const params = {
    Bucket: bucketName,
    Key: prefix ? `${prefix}/${name}.wav` : `${name}.wav`,
    Body: audioBlob,
    ContentType: "audio/wav",
  };

  try {
    const url = (await s3.upload(params).promise()).Location;
    return url;
  } catch (err) {
    throw err;
  }
};

export const uploadTextToS3 = async ({
  content,
  bucketName,
  name,
  prefix = "",
}) => {
  const params = {
    Bucket: bucketName,
    Key: prefix ? `${prefix}/${name}.json` : `${name}.json`,
    Body: content,
    ContentType: "application/json",
  };
  try {
    const url = (await s3.upload(params).promise()).Location;
    return url;
  } catch (err) {
    throw err;
  }
};
