import { Fragment } from "react";
import { SkeletonNavbar } from "../../components/Navbar";
function SkeletonLibrary() {
  return (
    <Fragment>
      <SkeletonNavbar />
      <div className="bg-gray mt-6 h-8 rounded-full w-[40%] animate-pulse"/>
      <div className="flex flex-col gap-5 mt-5">
        {[1,1,1,1].map((item, index) => <div key={index} className="bg-gray animate-pulse h-14 rounded-lg w-full"/>)}
      </div>
      <div className="bg-gray h-10 mt-auto rounded-full animate-pulse" />
    </Fragment>
  );
}

export default SkeletonLibrary;