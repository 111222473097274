import { Fragment } from "react";
import { SkeletonNavbar } from "../../components/Navbar";
function SkeletonHome() {
  return (
    <Fragment>
      <SkeletonNavbar />
      <div className="grid grid-cols-2 gap-5 mt-5">
        {[1,1,1,1].map((item, index) => (
          <div key={index} className="flex flex-col gap-4">
            <div className="h-[150px] rounded-lg bg-gray animate-pulse"/>
            <div className="flex gap-4">
              <div className="size-10 rounded-full bg-gray animate-pulse"/>
              <div className="grow my-2 rounded-full bg-gray animate-pulse"/>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-gray h-10 mt-auto rounded-full animate-pulse" />
    </Fragment>
  );
}

export default SkeletonHome;
