import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { discoverPodcast } from "../../../utils/api-helper";

export const fetchPodcasts = createAsyncThunk(
  "podcasts/fetchPodcasts",
  discoverPodcast
);

// Create the podcast slice
const podcastSlice = createSlice({
  name: "podcasts",
  initialState: {
    podcasts: [],
    loading: false,
    error: null,
    filterApplied : false,
    filterData : []
    
  },
  reducers: {
    filterPodcasts: (state, action) => {
      const { keyword } = action.payload;
      if (keyword === ""){
        state.filterData = state.podcasts;
        return
      }
      const filteredPodcasts = state.podcasts.filter((podcast) => {
        const podcastTitle = podcast.title.toLowerCase();
        const podcastDescription = podcast.description.toLowerCase();
        return (
          podcastTitle.includes(keyword.toLowerCase()) ||
          podcastDescription.includes(keyword.toLowerCase())
        );
      });
      state.filterApplied = true; 
      state.filterData = filteredPodcasts; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodcasts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPodcasts.fulfilled, (state, action) => {
        state.loading = false;
        state.podcasts = action.payload;
      })
      .addCase(fetchPodcasts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {filterPodcasts} = podcastSlice.actions;
export default podcastSlice.reducer;
