import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "./slice/Auth";
import discoverSlice from "./slice/podcast/PodcastSlice";
import Appconfig from "./slice/Appconfig";
import chatSlice from "./slice/chat";
import CurrentPodcastSlice from "./slice/podcast/currentPlaingPodcast";
import sharablePodcastSlice from "./slice/podcast/sharablePodcast";
import ProfileSlice from "./slice/profile";
import UserProfile from "./slice/userProfile";
import CurrentFollowPreivewSlice from "./slice/follow/userFollowPreview";

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    discover: discoverSlice,
    config: Appconfig,
    chat: chatSlice,
    currentPodcast: CurrentPodcastSlice,
    sharablePodcast: sharablePodcastSlice,
    profile: ProfileSlice,
    userCustomProfile: UserProfile,
    currentFollowPreview: CurrentFollowPreivewSlice,
  },
});

export default store;
