import { Fragment, useState, useRef, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { Sidebar, Bot, Menu } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { addChat, addPodcastRes, setState } from "../../redux/slice/chat";
import axios from "axios";
import { Send, Plus } from "lucide-react";
import VoiceRecorderModal from "../../components/modal/AudioModal";
import { uploadTextToS3 } from "../../utils/aws";
import api from "../../utils/api";
import { setSideBar } from "../../redux/slice/Appconfig";

function Create() {
  const { chat, chatloading, chaterror } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const audioModalRef = useRef();
  const inputRef = useRef();
  const { user, chatState } = useSelector((state) => state.auth);
  const [canSync, setCanSync] = useState(false);

  const chatPrompt = [
    {
      role: "user",
      parts: [
        {
          text: "You are finding out what the user loves to do, or what interesting life events they have done, or what they want the podcast to be about. Upon knowing something, tell them that a podcast with XYZ info becomes very listenable, and then ask a question regarding that to gather data and keep finding out more data for a good podcast while telling them in every alternative answer (not in every answer) how certain info will help their podcast be famous in a certain way—e.g., lots of people want to know about this—you should\nprovide this info as knowing appeals to user about this etc... and all your responses should be less than 25-30 words each. Do no keep saying how something will be useful all the time, and build up conversation to make the user subtly excited and  feel they're about to create the best podcast in the world.",
        },
      ],
    },
    {
      role: "model",
      parts: [
        {
          text: "Let'''s",
        },
      ],
    },
  ];
  const chatContainerRef = useRef(null);


  // chat logic
  async function gemeniChatResponse(m) {
    if (!m) {
      return;
    }
    setCanSync(true);
    setMessage("");

    // add new chat
    dispatch(
      addChat({
        role: "user",
        parts: [{ text: m }],
      })
    );

    // get gemeni response
    try {
      const res = await axios.post(
        `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${process.env.REACT_APP_GEMENI_KEY}`,
        {
          contents: [
            ...chatPrompt, // conbining promt
            ...chat,      // chat so far alng with current message
            {
              role: "user",
              parts: [{ text: m }],
            },
          ],
          // some other config for gemeni (copy as it is)
          generationConfig: {
            temperature: 1,
            topK: 64,
            topP: 0.95,
            maxOutputTokens: 8192,
            responseMimeType: "text/plain",
          },
        }
      );

      // parsing response
      const parts = res.data.candidates[0].content.parts;
      const AIResponse = parts.map((item) => item.text).join(" ");

      // useLess
      if (AIResponse.includes("TERMINATE CHAT")) {
        dispatch(setState({ state: "promt" }));
        setTimeout(() => audioModalRef.current.showModal(), 1000);
        return;
      }

      // add modal response
      dispatch(
        addChat({
          role: "model",
          parts: [{ text: AIResponse }],
        })
      );
    } catch (err) {

      // when gemeni give error (on unsual prompt)
      dispatch(
        addChat({
          role: "model",
          parts: [{ text: "unfortunately AI cannot repond to your message at this point." }],
        })
      );
    }
  }

  // to check is create button is active or not
  const forceCreatePodcast =
    chat.filter((item, index) => item.role === "user").length >= 5;


  // Use useEffect hook to scroll to the bottom after chat updates
  useEffect(() => {
    if (chatContainerRef.current) {
      // Check for overflow before scrolling
      const chatContainer = chatContainerRef.current;

      const isOverflowing =
        chatContainer.scrollHeight > chatContainer.clientHeight;

      if (isOverflowing) {
        chatContainer.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [chat]);

  // useless
  useEffect(() => {
    if (chatState === "promt") {
      setTimeout(audioModalRef.current.showModal, 2000);
    }
  }, [chatState]);


  // save chat to s3
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (canSync) {
        uploadTextToS3({
          content: JSON.stringify(chat),
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          name: user.email,
          prefix: "userChats",
        })
        .then((url) => {

          // do not coninute if already exists
          if (user.userChatUrl) { 
            return;
          }
          api.post("/user/update-profile", {
            id: user.id,
            userData: {
              userChatUrl: url,
            },
          });
        })
          .catch((err) => console.log(err));
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [chat, chatloading]);

  // automatically send hi on first time interaction
  useEffect(() => {
    if (!chatloading && chat.length === 0) {
      gemeniChatResponse("hi");
    }
  }, [chatloading]);


  //  skeleton loading for chat
  // if (chatloading) {
  //   return (
  //     <Fragment>
  //       <button
  //         onClick={(e) => dispatch(setSideBar({ open: true }))}
  //         className="bg-gray rounded-full w-fit p-2"
  //       >
  //         <Menu />
  //       </button>
  //       {chat.length > 0 ? null : (
  //         <h1 className="text-gray-700 text-2xl font-bold self-center">
  //           let's setup your podcast
  //         </h1>
  //       )}

  //       <div className="h-10 self-end w-1/3  mt-6 rounded-md bg-gray animate-pulse" />
  //       <div className="h-24 self-start w-2/3  mt-6 rounded-md bg-gray animate-pulse" />
  //       <div className="h-16 self-end w-[40%]  mt-6 rounded-md bg-gray animate-pulse" />
  //       <div className="h-40 self-start w-2/3  mt-6 rounded-md bg-gray animate-pulse" />

  //       <div className="h-14 mt-auto mb-6 rounded-full bg-gray animate-pulse" />
  //     </Fragment>
  //   );
  // }

  // actual JSX
  return (
    <Fragment>
      <button
        onClick={(e) => dispatch(setSideBar({ open: true }))}
        className="bg-gray rounded-full w-fit p-2"
      >
        <Menu />
      </button>
      {chat.length > 0 ? null : (
        <h1 className="text-gray-700 text-2xl font-bold self-center">
          let's setup your podcast
        </h1>
      )}
      <section
        ref={chatContainerRef}
        className="flex flex-col grow gap-4 overflow-scroll scroll-none my-2"
      >
        {chat.map((item, index) => {
          return (
            <div
              style={{
                alignSelf: item.role === "user" ? "end" : "start",
              }}
              key={index}
              className="flex flex-col gap-4 max-w-[75%]"
            >
              {item.role === "model" ? (
                <div className="flex gap-2 items-center ">
                  <div className="size-6 rounded-full bg-gradient-to-br from-[#40c9ff] to-[#e81cff]" />
                  <span>Podspin AI</span>
                </div>
              ) : null}
              <span
                className={`${
                  item.role === "user" ? "bg-gray" : "bg-transparent"
                } text-white flex items-center gap-2 text-base px-3 py-1  rounded-2xl`}
              >
                {item.parts[0].text}
              </span>
            </div>
          );
        })}
      </section>

      {/* input field for chat */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          inputRef.current.focus();
          gemeniChatResponse(message);
        }}
        className="flex bg-gray items-center rounded-full p-2 gap-3 mt-auto h-14 bottom-4 w-full right-0"
      >
        <input
          value={message}
          ref={inputRef}
          onChange={(e) => setMessage(e.target.value)}
          className="text-white placeholder:text-white outline-none bg-transparent text-lg p-4 grow min-w-[50px] flex-shrink-1 rounded-full"
          placeholder="your message here.."
        />
        <button
          disabled={!message}
          className="bg-graylight outline-none disabled:text-grayExtraLight rounded-full text-gray-300 size-10 flex justify-center items-center flex-shrink-0"
        >
          <Send size={18} />
        </button>
      </form>
      <button
        disabled={!forceCreatePodcast}
        type="button"
        onClick={() => audioModalRef.current.showModal()}
        className="disabled:text-grayExtraLight py-2"
      >
        create podcast


      {/* create button */}
      </button>
      {forceCreatePodcast ? null : (
        <div className="h-1 mx-6 mt-2 rounded-full bg-zinc-600 ">
          <div
            className="bg-white h-full"
            style={{
              width: `${
                (chat.filter((item) => item.role === "user").length / 5) * 100
              }%`,
            }}
          />
        </div>
      )}

      <VoiceRecorderModal modalRef={audioModalRef} />
    </Fragment>
  );
}

export default Create;

// user store his voice from api and get voice id (voice cloning) (save with user details);
// user chat send to backend to merge all the chat, get full audio, text script with time stamps
// split full audio according to speaker
// merge audio based on time stamp from text file
