import React, { useRef, useState, useEffect, Fragment } from "react";
import { Menu, Search, X } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { filterPodcasts } from "../redux/slice/podcast/PodcastSlice";
import { useLocation } from "react-router-dom";
import { setSideBar } from "../redux/slice/Appconfig";
export default function Navbar({ nav, search }) {
  const [active, setActive] = useState("discover");
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchInputRef = useRef();

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch(filterPodcasts({ keyword: searchKeyword }));
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchKeyword, dispatch]);

  function handleSearchToggle() {
    setSearchEnabled(!searchEnabled);
    if (!searchEnabled) {
      setSearchKeyword("");
    } else {
      console.log("clearing filter");
      dispatch(filterPodcasts({ keyword: "" }));
    }
  }

  function handleKeywordChange(e) {
    setSearchKeyword(e.target.value);
  }

  return (
    <header className="flex justify-between gap-4">
      {searchEnabled ? (
        <input
          type="text"
          className="p-2 outline-none rounded-lg grow bg-gray text-white"
          value={searchKeyword}
          onChange={handleKeywordChange}
          placeholder="Search"
        />
      ) : (
        <Fragment>
          <button
            onClick={(e) => {
              dispatch(setSideBar({ open: true }));
            }}
            className="p-3 rounded-full bg-gray"
          >
            <Menu className="text-white" size={20} onClick={(e) => {}} />
          </button>
          {nav ? (
            <nav
              className={`bg-gray grow rounded-xl overflow-hidden p-1 grid grid-cols-2 text-white`}
            >
              <Link
                to="/home"
                className={`${
                  pathname === "/home" ? "bg-white text-black" : ""
                } rounded-lg flex justify-center items-center text-lg font-semibold`}
                onClick={(e) => {
                  setActive("discover");
                }}
              >
                Discover
              </Link>
              <Link
                to="/library"
                className={`${
                  pathname === "/library" ? "bg-white text-black" : ""
                } rounded-lg flex justify-center items-center text-lg font-semibold`}
                onClick={(e) => {
                  setActive("library");
                }}
              >
                Library
              </Link>
            </nav>
          ) : null}
        </Fragment>
      )}
      {search ? (
        searchEnabled ? (
          <button
            onClick={handleSearchToggle}
            className="p-2 rounded-full outline-none bg-gray text-white"
          >
            <X className="text-white" size={20} />
          </button>
        ) : (
          <button className="p-3 rounded-full bg-gray">
            <Search
              className="text-white"
              size={20}
              onClick={handleSearchToggle}
            />
          </button>
        )
      ) : null}
    </header>
  );
}

export function SkeletonNavbar() {
  return (
    <header className="flex justify-between gap-4">
      <div className="size-10 rounded-full bg-gray animate-pulse" /> {" "}
      <nav className="bg-gray animate-pulse grow rounded-xl overflow-hidden p-1 grid grid-cols-2 text-white">
        <span
          className={`rounded-lg flex justify-center items-center text-lg `}
        />
        <span
          className={`rounded-lg flex justify-center items-center text-lg `}
        />
      </nav>
      <div className="size-10 rounded-full bg-gray animate-pulse" /> {" "}
    </header>
  );
}
