import { useSelector } from "react-redux";

import SkeletonLibrary from "../pages/skeleton/SkeletonLibrary";
import SkeletonHome from "../pages/skeleton/skeletonHome";
import Error from "../pages/error/Error";

import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Fragment, useState } from "react";
import Navbar from "./Navbar";
import SideBar from "./SideBar";

import AudioPlayer from "./AudioPlayer";

const skeletonUrlMap = {
  "/home" : <SkeletonHome />,
  "/library" : <SkeletonLibrary />
}

function ProtectedRoute() {
  const { user, loading } = useSelector((state) => state.auth);
  const { pathname }= useLocation();

  if (loading) {
    return skeletonUrlMap[pathname] || <SkeletonHome />;
  }

  if (user) {
    return (
      <Fragment>
        <SideBar />
        <Outlet />
        <AudioPlayer />
      </Fragment>
    );
  }

  return <Navigate to="/login" />;

  // // return <SkeletonHome />;
  // return (
  //   <Fragment>
  //     <SideBar />
  //     <Navbar />
  //     <Outlet />
  //   </Fragment>
  // );
}

export default ProtectedRoute;
