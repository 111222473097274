import { X } from "lucide-react";
function SkeletonSideBar({ sideBar, onClose}) {
  return (
    <nav
      className={`text-white flex fixed gap-4 w-[350px] transition-transform flex-col p-4 z-50 top-0 left-0 bg-gray-800 border-r-gray-700 border-r-2 h-[100dvh] ${
        sideBar ? "translate-x-0" : "translate-x-[-999px]"
      }`}
    >
      <button
        onClick={onClose}
        className="absolute right-4 top-4"
      >
        <X />
      </button>
      <div className="size-20 self-center bg-gray-600 animate-pulse rounded-full" />
      <div className="h-8 w-4/5 bg-gray-600 animate-pulse rounded-full" />
      <div className="h-8 w-2/3 bg-gray-600 animate-pulse rounded-full" />
      <div className="gap-4 flex flex-col my-auto">
        <div className="h-14 animate-pulse bg-gray-700 w-4/5 rounded-full" />
        <div className="h-14 animate-pulse bg-gray-700 w-4/5 rounded-full" />
        <div className="h-14 animate-pulse bg-gray-700 w-4/5 rounded-full" />
      </div>
      <div className="h-14 mt-auto mx-auto animate-pulse bg-gray-700 w-2/5 rounded-full" />
    </nav>
  );
}

export default SkeletonSideBar;
