import React, { useState, useEffect, useRef, Fragment } from "react";
import { uploadBlobToS3 } from "../../utils/aws";
import { useSelector, useDispatch } from "react-redux";
import api from "../../utils/api";
import { clearChat } from "../../redux/slice/chat";
import { setFirstTimePodcast } from "../../redux/slice/Appconfig";
import {
  X,
  Mic,
  CloudFog,
  Cloud,
  LoaderCircle,
  ArrowRight,
  Check,
} from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { fetchdatafroms3, uploadJsonToS3, uploadTextToS3 } from "../../utils/aws";
import { useNavigate } from "react-router-dom";

const VoiceRecorderModal = ({ modalRef }) => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isInputElement, setIsInputElement] = useState(false);
  const [input, setInput] = useState("");
  const [inputSubmit, setInputSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState();
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.start();
    setRecording(true);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const url = URL.createObjectURL(audioBlob);
      audioChunksRef.current = [];
      handleFinish({ blobURL: url });
    };
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  };

  async function handleFinish({ blobURL }) {
    setLoading(true);
    try {
      const url = await uploadBlobToS3({
        audioURL: blobURL,
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        name: user?.email,
        prefix: "userVoice",
      });
      // update user profile

      await api.post("/user/update-profile", {
        id: user.id,
        userData: {
          userVoiceUrl: url,
        },
      });

      setDone(true);
      setError(null);
    } catch (err) {
      console.log(err?.message);
      setError(err?.message);
    } finally {
      dispatch(clearChat());
      setLoading(false);
      setIsInputElement(false);
      localStorage.setItem("disable-chat-fetch", true)
    }
  }

  async function onSubmit() {
    setSubmitLoading(true);
    // fetch data from
    try {
      let data =
        (await fetchdatafroms3(
          "himank-funny-image-upload",
          "podcast-interest.json"
        )) || [];

      // if (data.includes(input)) {
      //   setIsInputElement(false);
      //   setInputSubmit(true);
      //   return;
      // }

      data.push({ input, type: "podcast" });

      // upload data to s3
      await uploadJsonToS3(
        "himank-funny-image-upload",
        "podcast-interest.json",
        data
      );
    } catch (err) {
      console.log(err);
    }

    setSubmitLoading(false);
    setInputSubmit(true);
  }

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        dispatch(setFirstTimePodcast({ data: true }));
        navigate("/library");
      }, 3000);
    }
  }, [done]);

  return (
    <dialog ref={modalRef} className="bg-transparent">
      <div className="flex flex-col min-h-[200px] relative gap-4 p-6 min-w-[300px] bg-gray text-white border-none rounded-xl">
        {done ? (
          <Fragment>
            <button
              onClick={(e) => {
                setIsInputElement(true);
                setDone(false);
                setRecording(false);
                setSubmitLoading(false);
                setLoading(false);
                setInput(false);
                setInputSubmit(false);
                setError(null);
                modalRef.current.close();
              }}
              className="absolute outline-none top-4 right-4"
            >
              <X />
            </button>
            <FontAwesomeIcon icon={faCircleCheck} fontSize={50} />
            <h1 className="self-center text-center">
              spinning up your pod!
              <br />
              est. time ~9 minutes.
              <br /> please check back soon!
            </h1>
            {
              isInputElement ? (
                <div className="flex gap-4 ">
                  {inputSubmit ? (
                    <span className="grow text-center">
                      you'll be notified shortly
                    </span>
                  ) : (
                    <input
                      onChange={(e) => setInput(e.target.value)}
                      className="bg-transparent placeholder:text-graylight grow border-b border-grayExtraLight pb-2 text-center outline-none"
                      placeholder="email or phone"
                    />
                  )}

                  {!input ? null : inputSubmit ? null : (
                    <button
                      onClick={onSubmit}
                      type="button"
                      className="text-white border-white border-[2px] size-8 flex justify-center items-center rounded-full"
                    >
                      {submitLoading ? (
                        <LoaderCircle size={15} className="animate-spin" />
                      ) : submitLoading ? (
                        <Check size={15} />
                      ) : (
                        <ArrowRight size={15} />
                      )}
                    </button>
                  )}
                </div>
              ) : null
              // <button
              //   onClick={(e) => {
              //     setIsInputElement(true);
              //     setInputSubmit(false);
              //   }}
              //   className="bg-graylight py-2 rounded-full"
              // >
              //   Notify me over text
              // </button>
            }
          </Fragment>
        ) : (
          <Fragment>
            <button
              onClick={(e) => modalRef.current.close()}
              className="absolute outline-none top-4 right-4"
            >
              <X />
            </button>
            <h1 className="text-xl font-bold">Voice Recorder</h1>
            <h1>
              Please record the following in the microphone so your podcast can
              be in your voice!
            </h1>
            <i className="italics">
              "I'd love to see my podcast generated by AI on Podspin app. I
              willingly give my consent to use my voice only for the purpose for
              generating this one podcast, and more if I request them from the
              app. This recording is for demo voice cloning purposes only, and
              will not be used elsewhere"
            </i>
            <button
              disabled={loading}
              onClick={recording ? stopRecording : startRecording}
              className="flex justify-center disabled:text-grayExtraLight self-center size-16 bg-graylight items-center rounded-full"
            >
              {recording ? (
                <FontAwesomeIcon size={"2x"} icon={faMicrophone} />
              ) : (
                <Mic size={31} />
              )}
            </button>
            <p
              className={`self-center ${loading ? "text-grayExtraLight" : ""}`}
            >
              {recording
                ? "you voice is being recorded"
                : "Tap to start recording"}
            </p>
            {loading ? (
              <p className={`flex gap-4 self-center`}>
                {" "}
                <LoaderCircle className="animate-spin" />
                {"your audio is uploading"}
              </p>
            ) : error ? (
              <span className="text-red-300 flex gap-4 self-center">
                {error}
              </span>
            ) : (
              ""
            )}
          </Fragment>
        )}
      </div>
    </dialog>
  );
};

export default VoiceRecorderModal;
