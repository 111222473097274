import { createSlice } from "@reduxjs/toolkit";

const CurrentPlayingPodcast = createSlice({
  name: "currentPlayingPodcast",
  initialState: {
    url: "",
    duration: 0,
    progress: 0,
    id: "",
    title: "",
    cover: "",
    isPlaying: false,
    userID: "",
  },
  reducers: {
    setCurrentPodCast: (state, action) => {
      state.url = action.payload.url;
      state.duration = action.payload.duration;
      state.title = action.payload.title;
      state.cover = action.payload.cover;
      state.id = action.payload.id;
      state.userID = action.payload.userID;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
});

export const { setCurrentPodCast, setProgress, setIsPlaying } =
  CurrentPlayingPodcast.actions;
export default CurrentPlayingPodcast.reducer;
