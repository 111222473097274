import { Fragment, useState } from "react";
import { notify } from "../../utils/notifyHarshit";
import {
  googleLogin,
  updateUser,
  setMode,
  Applelogin,
  TwiiterLogin
} from "../../redux/slice/Auth";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { fetchPodcasts } from "../../redux/slice/podcast/PodcastSlice";
import { upsertUser } from "../../utils/api-helper";
// import { syncChat } from "../../redux/slice/chat";

import {
  fetchFollowerFollowing,
  fetchUserPodCast,
} from "../../redux/slice/profile";
import { LoaderCircle } from "lucide-react";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function googlelogin() {
    dispatch(setMode("manuall"));
    dispatch(
      googleLogin(async function (payload) {
        setLoading(true);
        const userDetails = await upsertUser(payload);
        try {
          const notification = await notify({
            email: userDetails.email,
            name: userDetails.userName,
          });
          console.log("notified", notification);
        } catch (err) {
          console.log(err);
        }
        dispatch(fetchPodcasts());
        dispatch(fetchFollowerFollowing({ userID: userDetails.id }));
        dispatch(fetchUserPodCast({ userID: userDetails.id }));
        // if (userDetails.userChatUrl && !localStorage.getItem("disable-chat-fetch")){
        //   dispatch(syncChat(userDetails.userChatUrl))
        // }
        navigate("/home");
        setLoading(false);
        return userDetails;
      })
    );
  }
  function applelogin() {
    dispatch(setMode("manuall"));
    dispatch(
      Applelogin(async function (payload) {
        setLoading(true);
        const userDetails = await upsertUser(payload);
        try {
          const notification = await notify({
            email: userDetails.email,
            name: userDetails.userName,
          });
          console.log("notified", notification);
        } catch (err) {
          console.log(err);
        }

        dispatch(fetchPodcasts());
        dispatch(fetchFollowerFollowing({ userID: userDetails.id }));
        dispatch(fetchUserPodCast({ userID: userDetails.id }));
        // if (userDetails.userChatUrl && !localStorage.getItem("disable-chat-fetch")){
        //   dispatch(syncChat(userDetails.userChatUrl))
        // }
        navigate("/home");
        setLoading(false);
        return userDetails;
      })
    );
  }

  // function twitterlogin() {
  //   dispatch(setMode("manuall"));
  //   dispatch(
  //     TwiiterLogin(async function (payload) {
  //       setLoading(true);
  //       const userDetails = await upsertUser(payload);
  //       // try {
  //       //   const notification = await notify({
  //       //     email: userDetails.email,
  //       //     name: userDetails.userName,
  //       //   });
  //       //   console.log("notified", notification);
  //       // } catch (err) {
  //       //   console.log(err);
  //       // }

  //       // dispatch(fetchPodcasts());
  //       // dispatch(fetchFollowerFollowing({ userID: userDetails.id }));
  //       // dispatch(fetchUserPodCast({ userID: userDetails.id }));
  //       // navigate("/home");
  //       // setLoading(false);
  //       // return userDetails;
  //     })
  //   );
  // }

  return (
    <Fragment>
      <div className="mx-auto gap-4 text-white bg-gray my-auto p-6 rounded-2xl bg-gray-900 flex flex-col">
        {loading ? (
          <span className="text-xl">almost there…</span>
        ) : (
          <Fragment>
            <h1 className="text-2xl mx-auto ">Create an account</h1>

            <section className="flex flex-col gap-4 my-8">
              <button
                disabled={loading}
                onClick={googlelogin}
                className="bg-graylight outline-none cursor-pointer flex items-center gap-3 justify-center  py-4 rounded-full px-6"
              >
                <img src="/svg/google-icon.svg" className="size-4" />
                <span className="grow">continue with google</span>
              </button>
              <button
                disabled={loading}
                onClick={applelogin}
                className="bg-graylight outline-none flex items-center gap-3 justify-center py-4 rounded-full px-6"
              >
                <img className="size-4" src="/svg/apple-icon.svg" />
                <span className="grow">continue with Apple</span>
              </button>
              {/* <button
                disabled={loading}
                onClick={twitterlogin}
                className="bg-graylight outline-none flex items-center gap-3 justify-center py-4 rounded-full px-6"
              >
                <img className="size-4" src="/svg/x-icon.svg" />
                <span className="grow">continue with twitter</span>
              </button> */}
            </section>

            <span className="text-white font-light text-sm text-center">
              By continuing, you agree to our{" "}
              <br />
              <Link className="underline" to="/terms">
                Terms{" "}
              </Link>
              and acknowledge our{" "}
              <Link className="underline" to="/privacy">
                Privacy Policy
              </Link>
            </span>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Login;
