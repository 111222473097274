import React, { Fragment, useState, useEffect } from "react";
import Navbar, { SkeletonNavbar } from "../../components/Navbar";
import { Music, LoaderCircle, Check, ArrowRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLibrary from "../skeleton/SkeletonLibrary";
import PodcastListItem from "../../components/PodcastListItem";
import { Link } from "react-router-dom";
import { clearFirstTimePodcast } from "../../redux/slice/Appconfig";
import moment from "moment-timezone";
import { fetchdatafroms3, uploadJsonToS3 } from "../../utils/aws"



function Library() {
  const { data, loading, error } = useSelector(
    (state) => state.profile.podcast
  );

  const [isInputElement, setIsInputElement] = useState(false);
  const [input, setInput] = useState("");
  const [inputSubmit, setInputSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState();

  const firstTimePodcast = useSelector(
    (state) => state.config.firstTimePodcast
  );
  const time = localStorage.getItem("timeout") || null;

  if (loading) {
    return <SkeletonLibrary />;
  }

  async function onSubmit() {
    setSubmitLoading(true);
    // fetch data from
    try {
      let data =
        (await fetchdatafroms3(
          "himank-funny-image-upload",
          "podcast-interest.json"
        )) || [];

      // if (data.includes(input)) {
      //   setIsInputElement(false);
      //   setInputSubmit(true);
      //   return;
      // }

      data.push({ input, type: "podcast" });

      // upload data to s3
      await uploadJsonToS3(
        "himank-funny-image-upload",
        "podcast-interest.json",
        data
      );
    } catch (err) {
      console.log(err);
    }

    setSubmitLoading(false);
    setInputSubmit(true);
  }

  return (
    <Fragment>
      <Navbar nav search />
      <section className="overflow-scroll grow flex gap-4 flex-col mt-6 mb-3 scroll-none">
        <h1 className="text-white mb-3 font-bold text-xl">your podcasts</h1>
        {firstTimePodcast && time ? (
          <Fragment>
            <CountdownTimer timer={time} />

            <button
              onClick={(e) => {
                setIsInputElement(true);
                setInputSubmit(false);
              }}
              className="bg-graylight self-center px-6 py-2 mt-7 rounded-full"
            >
              Notify me over text
            </button>

            {isInputElement ? (
              <div className="self-center flex items-center gap-2">
                {inputSubmit ? (
                  <span className="grow text-center">
                    you'll be notified shortly
                  </span>
                ) : (
                  <input
                    onChange={(e) => setInput(e.target.value)}
                    className="bg-transparent placeholder:text-graylight grow border-b border-grayExtraLight pb-2 text-center outline-none"
                    placeholder="email or phone"
                  />
                )}
                   {!input ? null : inputSubmit ? null : (
                  <button
                    onClick={onSubmit}
                    type="button"
                    className="text-white border-white border-[2px] size-8 flex justify-center items-center rounded-full"
                  >
                    {submitLoading ? (
                      <LoaderCircle size={15} className="animate-spin" />
                    ) : submitLoading ? (
                      <Check size={15} />
                    ) : (
                      <ArrowRight size={15} />
                    )}
                  </button>
                )}
              </div>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            {loading ? null : data.length > 0 ? (
              data.map((item) => <PodcastListItem key={item.id} {...item} />)
            ) : (
              <div className="text-xl mx-auto grow px-auto justify-center text-center flex flex-col">
                <span>create your podcast</span>
                <Link
                  to="/create"
                  className="flex gap-3 items-center my-6 bg-white text-black py-4 px-6 self-center rounded-full"
                >
                  <Music />
                  create
                </Link>
              </div>
            )}
          </Fragment>
        )}
      </section>
    </Fragment>
  );
}

function CountdownTimer({ timer }) {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const targetTime = parseInt(timer);

    const intervalId = setInterval(() => {
      const now = Math.floor(Date.now());
      const remainingTime = targetTime - now;
      setCountdown(Math.floor(remainingTime / 1000));
      if (remainingTime < 1) {
        dispatch(clearFirstTimePodcast());
      }
      console.log(Math.floor(remainingTime / 1000));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const minutes = Math.floor(countdown / 60);
  const seconds = Math.floor(countdown % 60);

  function addZeros(a) {
    return a < 10 ? `0${a}` : a;
  }

  return (
    <div className="flex gap-4 items-center font-thin self-center mt-12">
      <div className="flex flex-col items-center">
        <span className="text-[30px] md:text-[50px]">{addZeros(minutes)}</span>
        <span className="text-xs md:text-base">Minutes</span>
      </div>
      <span className="text-[15px] md:text-[30px] text-gray-400 mb-5">:</span>
      <div className="flex flex-col items-center">
        <span className="text-[30px] md:text-[50px]">{addZeros(seconds)}</span>
        <span className="text-xs md:text-base">Seconds</span>
      </div>
    </div>
  );
}

export default Library;
