import axios from "axios";
import { getCookie } from "./cookie";
const api = axios.create({
  baseURL: "https://2i0rwla6ge.execute-api.us-east-2.amazonaws.com/dev",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
  },
});

export default api;
