import { useDispatch } from "react-redux";
import { setCurrentPodCast } from "../redux/slice/podcast/currentPlaingPodcast";
import { setAudioPlayerStatus } from "../redux/slice/Appconfig";
import { Share2 } from "lucide-react";

export default function PodcastListItem(props) {
    async function copy() {
      try {
        const domain = window.location.href.split("://")[1].split("/")[0];
        const url = `${
          process.env.NODE_ENV === "development" ? "http" : "https"
        }://${domain}/#/listen/${props.userID}`;
        await navigator.clipboard.writeText(url);
        alert("url copied");
      } catch (err) {
        alert(err?.message);
      }
    }
    const { title, imageUrl, duration } = props;
    const dispatch = useDispatch();

    function secondsToHMS(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
  
      // Build the time string conditionally based on the hours
      if (hours > 0) {
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(secs).padStart(2, "0")}`;
      } else {
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
          2,
          "0"
        )}`;
      }
    }
  
    return (
      <div
        onClick={(e) => {
          dispatch(
            setCurrentPodCast({
              ...props,
              cover: props.imageUrl,
            })
          );
          dispatch(setAudioPlayerStatus({ status: "fullscreen" }));
        }}
        className="flex  gap-2 bg-gray p-2 rounded-lg"
      >
        <img src={imageUrl || "/default.png"} className="size-16 rounded-md object-cover" />
        <div className="flex flex-col justify-center grow">
          <span className="text-white ">{title}</span>
          <span className="text-gray-300 ">{secondsToHMS(parseInt(duration))}</span>
        </div>

      </div>
    );
  };