import { Fragment, useState } from "react";
import CountdownTimer from "../../components/Counter";
import { Check, ArrowRight, LoaderCircle } from "lucide-react";
import { fetchdatafroms3, uploadJsonToS3 } from "../../utils/aws";

function Waitlist() {
  const [input, setInput] = useState(true);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function onSubmit() {
    if (!email) {
      return;
    }
    setLoading(true);
    // fetch data from
    try {
      let data =
        (await fetchdatafroms3(
          "himank-funny-image-upload",
          "podcast-interest.json"
        )) || [];

      if (data.includes(email)) {
        setLoading(false);
        setSuccess(true);
        return;
      }

      data.push(email);

      // upload data to s3
      await uploadJsonToS3(
        "himank-funny-image-upload",
        "podcast-interest.json",
        data
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
    setSuccess(true);
  }

  return (
    <div className="text-white">
      <div className="flex flex-col mt-[100px]">
        <h1 className="text-[40px] md:text-[60px] text-center font-thin my-14">
          podspin
        </h1>
        <span
         
          className="text-white self-center font-bold text-2xl text-center mt-8 "
        >
          {success
            ? "Thank You for submitting your response"
            : "get on the waitlist now!"}
        </span>

        <div className="self-center flex min-w-[80%] mt-2 py-4">
          {success ? (
            <span className="self-center grow text-center">
              You will be notified soon
            </span>
          ) : (
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="youremail@example.com"
              className="bg-transparent text-center border-b grow outline-none py-2 px-1"
            />
          )}

          <button
            onClick={onSubmit}
            className="text-white border-white border-[2px] size-8 flex justify-center items-center rounded-full"
          >
            {loading ? (
              <LoaderCircle size={15} className="animate-spin" />
            ) : success ? (
              <Check size={15} />
            ) : (
              <ArrowRight size={15} />
            )}
          </button>
        </div>

        <CountdownTimer />
      </div>
    </div>
  );
}

export default Waitlist;
